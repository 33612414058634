import * as L from "leaflet";
import MapHelper from "../helpers/MapHelper";

export default class NearmapsOverlay {
    getOverlay(imageUrl, bbox) {
        //const imageUrl = 'https://api.pipesolar.lc/resources/nearmaps/984956cf3da211d3e3eb7a15f71b059a.jpg';
        const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];
        const latLngBounds = L.latLngBounds(bounds);

        const imageOverlay = L.imageOverlay(imageUrl, latLngBounds, {
            pane: 'tilePane',
            opacity: 1,
            interactive: false
        });

        /*const defaultOptions = (new MapHelper()).getGoogleDefaults();
        defaultOptions.type = 'hybrid';
        const backgroundTiles = L.gridLayer.googleMutant(defaultOptions);

        return L.layerGroup([imageOverlay]);*/
        return imageOverlay;
    }


    updateOverlay(imageOverlay, imageUrl, bbox) {
        const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];
        const latLngBounds = L.latLngBounds(bounds);

        imageOverlay.setBounds(latLngBounds);
        imageOverlay.setUrl(imageUrl);
    }
}