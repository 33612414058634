import React from 'react';
import Lottie from "lottie-react";
import confirmLoadingAnimation from "../../assets/css/animations/lf20_QUbOiL.json";
import {styled, keyframes} from '@stitches/react';
import {violet, blackA, red, mauve} from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

const overlayShow = keyframes({
    '0%': {opacity: 0},
    '100%': {opacity: 1},
});

const contentShow = keyframes({
    '0%': {opacity: 0, transform: 'translate(-50%, -48%) scale(.96)'},
    '100%': {opacity: 1, transform: 'translate(-50%, -50%) scale(1)'},
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
    backgroundColor: blackA.blackA3,
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 400ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    zIndex: 10000,
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: 'rgba(6,11,39,0.15) 0 8px 24px',
    position: 'fixed',
    top: 'calc(50% - 40px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    minWidth: '300px',
    maxWidth: '450px',
    height: 'auto',
    maxHeight: '85vh',
    zIndex: 1000000,
    padding: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '@media (max-width: 767px)': {
        width: 'calc(100vw - 20px)',
    },
    '&:focus': {outline: 'none'},
});

function Content({children, ...props}) {
    return (
        <AlertDialogPrimitive.Portal>
            {/*<StyledOverlay/>*/}
            <StyledContent {...props}>{children}</StyledContent>
        </AlertDialogPrimitive.Portal>
    );
}

const StyledTitle = styled(AlertDialogPrimitive.Title, {
    margin: 0,
    padding: '20px 25px',
    border: '0',
    color: '#2B2E32',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 600,
});

const StyledDescription = styled(AlertDialogPrimitive.Description, {
    marginBottom: 0,
    padding: 25,
    borderTop: '1px #dbdcdc solid',
    borderBottom: '1px #dbdcdc solid',
    color: '#2B2E32',
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 500,
});

// Exports
export const AlertDialog = styled(AlertDialogPrimitive.Root, {
    minWidth: '300px',
});
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogContent = Content;
export const AlertDialogTitle = StyledTitle;
export const AlertDialogDescription = StyledDescription;
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;

// Your app...
const Flex = styled('div', {display: 'flex'});

const Button = styled('button', {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    padding: '0px 30px',
    fontSize: 16,
    lineHeight: '120%',
    fontWeight: 400,
    height: 40,
    color: '#ffffff',

    variants: {
        variant: {
            red: {
                backgroundColor: '#F04242',
                '&:hover': {backgroundColor: '#2B2F33'},
                '&:hover:focus': {backgroundColor: '#2B2F33'},
            },
            blue: {
                backgroundColor: 'rgb(39, 110, 250)',
                border: '1px solid rgba(43, 47, 51, 0.14)',
                width: 83,
                fontSize: 16,
                fontWeight: 500,
                color: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(43 47 51 / 20%) 0px 2px 6px 0px',
                '&:hover': {backgroundColor: '#2B2F33'},
                '&:hover:focus': {backgroundColor: '#2B2F33'},
            },
            white: {
                backgroundColor: '#ffffff',
                border: '1px #dbdbdb solid',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.05)',
                color: 'rgb(43,47,51,1)',
                '&:hover': {color: '#286EFA', borderColor: '#286EFA'},
                '&:hover:focus': {color: '#286EFA', borderColor: '#286EFA'},
            },
        },
    },

    defaultVariants: {
        variant: 'white',
    },
});

const PSAlert = (props) => {
    /*const [open, setOpen] = React.useState(props.open);

    if (open != props.open) {
        setOpen(props.open);
    }*/

    return (
        <AlertDialog open={props.open}>
            <AlertDialogContent>
                <AlertDialogTitle>{props.title}</AlertDialogTitle>
                <AlertDialogDescription>
                    {props.description}
                </AlertDialogDescription>
                <Flex css={{justifyContent: 'flex-end', padding: '16px 25px'}}>
                    <AlertDialogCancel asChild onClick={() => {props.openChanged(false)}}>
                        <Button variant="white" disabled={props.cancelDisabled === true} css={(props.btnFixWidth ? {padding: 0, width: props.btnFixWidth+'px', marginRight: 12} : {marginRight: 12})}>
                            {props.cancelButtonLabel ? props.cancelButtonLabel : 'Keep Address'}
                        </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild onClick={props.onAccept}>
                        <Button variant={props.confirmButtonVariant ? props.confirmButtonVariant : 'red'} disabled={props.acceptDisabled === true} css={(props.btnFixWidth ? {padding: 0, width: props.btnFixWidth+'px'} : {})}>
                            {props.confirmLoading && (<Lottie animationData={confirmLoadingAnimation} loop={true} style={{width: '25px', height: '25px'}} />)}
                            {!props.confirmLoading && (props.confirmButtonLabel ? props.confirmButtonLabel : 'Delete Design')}
                        </Button>
                    </AlertDialogAction>
                </Flex>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default PSAlert;
