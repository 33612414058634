/*import React, { Component } from 'react';

class TPStorageField extends Component {
    constructor(props) {
        super(props);

        let defaultValue = props.value;
        if (!props.value && this.props.storages) {
            for (let storage of Object.values(this.props.storages)) {
                if (!defaultValue) {
                    defaultValue = storage.id;
                }
            }
        }

        this.storageChanged = this.storageChanged.bind(this);
    }

    storageChanged(e) {
        if (e.target.value != this.props.value) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const storagesFormatted = [{
            id: "-",
            text: "N/A"
        }];

        if (this.props.storages) {
            for (let storage of Object.values(this.props.storages)) {
                storagesFormatted.push({
                    id: storage.id,
                    text: storage.displayed_text
                });
            }
        }

        return (
            <div className="form-group">
                <Select-2
                    name="storage-type"
                    className="form-control styled-select"
                    data={storagesFormatted}
                    value={this.props.value}
                    options={{
                        placeholder: 'Storage Type',
                        minimumResultsForSearch: Infinity
                    }}
                    onChange={this.storageChanged}
                />
            </div>
        );
    }
}

export default TPStorageField;
*/