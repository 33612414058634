import React, { Component } from 'react';

import './Error.css';

import loadingImg from './loader.gif';

class Error extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: 'active'
        };

        this.errorLinkClicked = this.errorLinkClicked.bind(this);
    }

    errorLinkClicked(e) {
        e.preventDefault();

        if (this.state.status == 'active') {
            this.setState({
                status: 'processing'
            }, function() {
                const delay = 1000 + Math.round(Math.random() * 1000);

                setTimeout((that) => {
                    that.setState({status: 'passive'}, function() {
                        setTimeout((that) => {
                            that.setState({status: 'passive-visible'});
                        }, 300, this);
                    });
                }, delay, this);
            });
        }
    }

    static redirectToErrorPage() {
        if (process.env.HOST == 'design.pipe.solar') {
            window.location = '/404';
        }
    }

    render() {
        let contentClass = 'error-container-content';
        if (window.innerHeight < 500) {
            contentClass += ' short-height';
        }
        return (
            <div className="error-container">
                <div className={contentClass}>
                    <p className="number">404</p>
                    <p className="subheader">Oops, you’ve lost in space</p>
                    <p>We can’t find the page that you’re looking for</p>
                    <a href="" className={this.state.status} onClick={this.errorLinkClicked}>
                        <span className="active">Send a Report</span>
                        <span className="processing"><img src={loadingImg} /></span>
                        <span className="passive">
                            <svg height="417pt" viewBox="0 -46 417.81333 417" width="417pt" xmlns="http://www.w3.org/2000/svg"><path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"/></svg>
                            Thank you for feedback!
                        </span>
                    </a>
                </div>
            </div>
        );
    }
}

export default Error;
