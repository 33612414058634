import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {SelectStyles} from '../General/SelectStyles';

export default function TPInverterField(props) {
    const [invertersFormatted, setInvertersFormatted] = useState([]);


    useEffect(() => {
        let invertersFormattedTmp = [];

        if (props.inverters) {
            for (let inverter of Object.values(props.inverters)) {
                invertersFormattedTmp.push({
                    value: inverter.id,
                    label: inverter.displayed_text
                });
            }
        }

        setInvertersFormatted(invertersFormattedTmp);
    }, [props.inverters]);


    const inverterChanged = (selectedOption) => {
        if (selectedOption.value !== props.value) {
            props.onChange(selectedOption.value);
        }
    }


    const getSelectedInverter = () => {
        if (props.value && props.inverters) {
            const value = Object.values(props.inverters).find(inverter => inverter.id === props.value);

            return {
                value: value.id,
                label: value.displayed_text
            };
        }

        return null;
    }


    return (
        <div className="form-group">
            <Select
                name="inverter-type"
                classNamePrefix="ps-"
                value={getSelectedInverter()}
                placeholder="Inverter Type"
                options={invertersFormatted}
                onChange={inverterChanged}
                isSearchable={false}
                isMulti={false}
                styles={SelectStyles}
            />
        </div>
    );
}
