import * as L from "leaflet";
import {DomUtil, Point} from "leaflet";
import GeometryUtil from 'leaflet-geometryutil';

import {DynamicGrid} from "./DynamicGrid";

import tbDrag from "./../assets/img/tb-drag.svg";
import tbRotate from "./../assets/img/tb-rotate.svg";
import tbDelete from "./../assets/img/tb-delete.svg";
import * as turf from "@turf/turf";

/**
 * @namespace
 * @property {DynamicGrid} grid
 * @property {L.Map} map
 * @property {HTMLElement} _container
 */
class DynamicGridToolbar {
    /**
     *
     * @param {DynamicGrid} grid
     * @param {L.Map} map
     */
    constructor (grid, map) {
        this.map = map;
        this.grid = grid;

        this._container = DomUtil.create('div', 'dynamic-grid-toolbar', this.grid._layerContainer);

        this.info = {
            center: null,
            drag: {
                marker: null,
                init: {
                    x: 0,
                    y: 0
                }
            },
            rotate: {
                marker: null,
                phantomMarker: null,
                info: {}
            },
            delete: {
                marker: null,
            }
        };

        this.init();

        this.update = this.update.bind(this);
        this.dragMarkerDrag = this.dragMarkerDrag.bind(this);
        this.dragMarkerDragEnd = this.dragMarkerDragEnd.bind(this);
        this._onRotationMarkerMoved = this._onRotationMarkerMoved.bind(this);
        this._onRotateMarkerDragEnd = this._onRotateMarkerDragEnd.bind(this);
    }


    init() {
        this._containerPosition = this.getPosition();
        this._container.style.left = this.grid._originPoint.x + 'px';
        this._container.style.top = this.grid._originPoint.y + 'px';

        this.initDragMarker();
        this.initRotateMarker();
        this.initDeleteMarker();
    }


    /**
     * Update toolbar position
     */
    update(updateMarkers = true) {
        const position = this.getPosition();

        this._containerPosition = position;
        this._container.style.left = position.x + 'px';
        this._container.style.top = position.y + 'px';

        this.info.center = this.grid._latLngCenter;

        if (this.grid._latlngs.length && updateMarkers) {
            this.updateDragMarker();
            this.updateRotateMarker();
            this.updateDeleteMarker();
        }
    }


    /**
     * Remove toolbar
     */
    remove() {
        this.removeDragMarker();
        this.removeRotateMarker();
        this.removeDeleteMarker();
    }


    /**
     * Get base position for a toolbar
     * @param {int} number
     * @return {Point|false}
     */
    getPosition() {
        if (!this.grid._latlngs.length) {return false;}

        const middleTurfLatLng = turf.destination([this.grid._latLngCenter.lng, this.grid._latLngCenter.lat], this.grid._latLngRadius, 90, {units: 'meters'}),
            middleLatLng = L.latLng(middleTurfLatLng.geometry.coordinates[1], middleTurfLatLng.geometry.coordinates[0]),
            middlePoint = this.map.latLngToContainerPoint(middleLatLng),
            finalPoint = middlePoint.add(L.point(56, 0)),
            finalPointCorrected = finalPoint.subtract(this.map._getMapPanePos())

        //return this.grid.rotatePoint(middlePoint, (-1)*this.grid.getAzimuth(), finalPoint);
        return finalPointCorrected;
    }


    /**
     * Create an icon for the marker
     * @param {string} src
     * @return {HTMLDivElement}
     */
    getIcon(src) {
        let addClass = '';
        if (src.indexOf('drag') > -1) {
            addClass = 'tb-drag';
        } else if (src.indexOf('rotate') > -1) {
            addClass = 'tb-rotate';
        } else if (src.indexOf('delete') > -1) {
            addClass = 'tb-delete';
        }

        const domImg = DomUtil.create('img');
        domImg.src = src;

        const domIcon = DomUtil.create('div', 'toolbar-icon '+addClass);
        domIcon.appendChild(domImg);

        return domIcon;
    }


    /***  -----------------------------  Drag marker features  -----------------------------  ***/


    /**
     * Init drag marker
     */
    initDragMarker() {
        this.info.drag.marker = this.getIcon(tbDrag);

        this.info.drag.marker.addEventListener('mousedown', this.dragMarkerDragStart.bind(this));
        this.info.drag.marker.addEventListener('touchstart', this.dragMarkerDragStart.bind(this));

        this._container.appendChild(this.info.drag.marker);
    }


    /**
     * *dragstart* event listener for drag marker
     * @param {MouseEvent} e
     */
    dragMarkerDragStart(e) {
        e.preventDefault();
        this.map.dragging.disable();

        if (e.type === 'touchstart') {
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;
        }

        this.info.grid = this.grid._containerTranslate;
        this.info.containerPosition = this._containerPosition;
        this.info.drag.init.x = e.clientX;
        this.info.drag.init.y = e.clientY;

        document.addEventListener('mousemove', this.dragMarkerDrag);
        document.addEventListener('mouseup', this.dragMarkerDragEnd);
        //document.addEventListener('mouseout', this.dragMarkerDragEnd);

        document.addEventListener('touchmove', this.dragMarkerDrag);
        document.addEventListener('touchend', this.dragMarkerDragEnd);
        //document.addEventListener('touchcancel', this.dragMarkerDragEnd);
    }


    /**
     * *drag* event listener for drag marker
     * @param {MouseEvent} e
     */
    dragMarkerDrag(e) {
        e.preventDefault();

        if (e.type === 'touchmove') {
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;
        }

        const diffX = this.info.drag.init.x - e.clientX;
        const diffY = this.info.drag.init.y - e.clientY;

        this.grid._containerTranslate = new Point(this.info.grid.x - diffX, this.info.grid.y - diffY);
        this.grid._updateContainerTranslate();

        let newContainerPosition = this.info.containerPosition.subtract(new Point(diffX, diffY));
        this._container.style.left = newContainerPosition.x + 'px';
        this._container.style.top = newContainerPosition.y + 'px';
    }


    /**
     * *dragend* event listener for drag marker
     * @param {MouseEvent} e
     */
    dragMarkerDragEnd(e) {
        e.preventDefault();
        this.map.dragging.enable();

        document.removeEventListener('mousemove', this.dragMarkerDrag);
        document.removeEventListener('mouseup', this.dragMarkerDragEnd);
        //document.removeEventListener('mouseout', this.dragMarkerDragEnd);

        document.removeEventListener('touchmove', this.dragMarkerDrag);
        document.removeEventListener('touchend', this.dragMarkerDragEnd);
        //document.removeEventListener('touchcancel', this.dragMarkerDragEnd);

        this.grid._updateTilesLatLngPositions();
    }


    /**
     * Update drag marker
     */
    updateDragMarker() {
        if (this.grid._latlngs.length) {
            if (this.info.drag.marker == null) {
                this.initDragMarker();
            }
        } else {
            this.removeDragMarker();
        }
    }


    /**
     * Remove drag marker
     */
    removeDragMarker() {
        this.info.drag.marker.remove();
    }


    /*** -----------------------------  Rotate marker features  -----------------------------  ***/


    /**
     * Update rotation marker
     */
    updateRotateMarker() {
        if (this.grid._latlngs.length) {
            if (this.info.rotate.marker == null) {
                this.initRotateMarker();
            }
        } else {
            this.removeRotateMarker();
        }
    }


    /**
     * Remove rotation marker
     */
    removeRotateMarker() {
        this.info.rotate.marker.remove();
        this.info.rotate.phantomMarker.remove();
    }


    /**
     * Init rotate marker
     */
    initRotateMarker() {
        const markerContainer = DomUtil.create('div', 'tb-rotate-container');
        const marker = this.getIcon(tbRotate);
        const phantomMarker = this.getIcon(tbRotate);
        phantomMarker.classList.add('tb-rotate-phantom');

        phantomMarker.addEventListener('mousedown', this._onRotateMarkerDragStart.bind(this));
        phantomMarker.addEventListener('touchstart', this._onRotateMarkerDragStart.bind(this));

        markerContainer.appendChild(marker);
        markerContainer.appendChild(phantomMarker);

        this.info.rotate.markerContainer = markerContainer;
        this.info.rotate.marker = marker;
        this.info.rotate.phantomMarker = phantomMarker;

        this._container.appendChild(this.info.rotate.markerContainer);

        /*this.info.rotate.phantomMarker.on('dragstart', this._onRotateMarkerDragStart.bind(this));

        //hover effect
        this.info.rotate.phantomMarker.on('mouseover', function(e) {$('.tb-rotate').addClass('tb-rotate-hover');});
        this.info.rotate.phantomMarker.on('mouseout', function(e) {$('.tb-rotate').removeClass('tb-rotate-hover');});*/
    }

    /**
     * Event after rotate marker start dragging
     * @param {MouseEvent} e
     */
    _onRotateMarkerDragStart(e) {
        e.preventDefault();
        this.map.dragging.disable();

        let clientPosition = this._getEventPosition(e);

        this.info.center = this.grid._latLngCenter;

        this.info.rotate.info['initHeading'] = GeometryUtil.bearing(this.info.center, this.map.containerPointToLatLng(new Point(clientPosition.x, clientPosition.y)));
        this.info.rotate.info['initAzimuth'] = this.grid.getAzimuth();
        this.info.rotate.info['init'] = {
            x: clientPosition.x,
            y: clientPosition.y,
            offsetLeft: this.info.rotate.phantomMarker.offsetLeft,
            offsetTop: this.info.rotate.phantomMarker.offsetTop
        }

        document.addEventListener('mousemove', this._onRotationMarkerMoved);
        document.addEventListener('mouseup', this._onRotateMarkerDragEnd);
        //document.addEventListener('mouseout', this._onRotateMarkerDragEnd);

        document.addEventListener('touchmove', this._onRotationMarkerMoved);
        document.addEventListener('touchend', this._onRotateMarkerDragEnd);
        //document.addEventListener('touchcancel', this._onRotateMarkerDragEnd);
    }

    /**
     * Event after rotate marker moved
     * @param {MouseEvent} e
     */
    _onRotationMarkerMoved(e) {
        let clientPosition = this._getEventPosition(e);

        const diffX = this.info.rotate.info['init'].x - clientPosition.x;
        const diffY = this.info.rotate.info['init'].y - clientPosition.y;

        this.info.rotate.phantomMarker.style.left = (this.info.rotate.info['init'].offsetLeft - diffX) + "px";
        this.info.rotate.phantomMarker.style.top = (this.info.rotate.info['init'].offsetTop - diffY) + "px";

        const curLocation = this.map.containerPointToLatLng(new Point(clientPosition.x, clientPosition.y)),
            newHeading = GeometryUtil.bearing(this.info.center, curLocation),
            diffHeading = this.info.rotate.info['initHeading'] - newHeading,
            newAzimuth = this.info.rotate.info['initAzimuth'] - diffHeading;

        this.grid.rotate(newAzimuth, true);
        this.update(false);
    }

    /**
     * Event after rotate marker dragged
     * @param {MouseEvent} e
     */
    _onRotateMarkerDragEnd(e) {
        e.preventDefault();
        this.map.dragging.enable();

        /*setTimeout(function() {
            this.panel.mapper.passive = true;
        }, 500);*/

        document.removeEventListener('mousemove', this._onRotationMarkerMoved);
        document.removeEventListener('mouseup', this._onRotateMarkerDragEnd);
        //document.removeEventListener('mouseout', this._onRotateMarkerDragEnd);

        document.removeEventListener('touchmove', this._onRotationMarkerMoved);
        document.removeEventListener('touchend', this._onRotateMarkerDragEnd);
        //document.removeEventListener('touchcancel', this._onRotateMarkerDragEnd);

        this.grid._updateTilesLatLngPositions();
        this.info.rotate.phantomMarker.style.left = this.info.rotate.marker.style.left;
        this.info.rotate.phantomMarker.style.top = this.info.rotate.marker.style.top;
    }


    /** -----------------------------  Delete marker features  -----------------------------  **/


    /**
     * Init delete marker
     */
    initDeleteMarker() {
        this.info.delete.marker = this.getIcon(tbDelete);
        this.info.delete.marker.addEventListener('mouseup', e => this.grid.selfDelete());
        this.info.delete.marker.addEventListener('touchend', e => this.grid.selfDelete());

        this._container.appendChild(this.info.delete.marker);
    }


    /**
     * Update delete marker
     */
    updateDeleteMarker() {
        if (this.grid._latlngs.length) {
            if (this.info.delete.marker == null) {
                this.initDeleteMarker();
            }
        } else {
            this.removeDeleteMarker();
        }
    }


    /**
     * Remove delete marker
     */
    removeDeleteMarker() {
        this.info.delete.marker.remove();
    }



    _getEventPosition(e) {
        let mapPosition = {
            x: document.querySelector('#mapContainer').getBoundingClientRect().left,
            y: document.querySelector('#mapContainer').getBoundingClientRect().top
        };

        let position;
        if (e.type.indexOf('touch') !== -1) {
            position = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
        } else {
            position = {
                x: e.clientX,
                y: e.clientY
            };
        }

        position.x -= mapPosition.x;
        position.y -= mapPosition.y;

        return position;
    }
}

export default DynamicGridToolbar;