import React, { Component } from 'react';
import * as turf from "@turf/turf";

import imgSetObstructions from './../../assets/img/obstructions.svg';
import obstructionsBg from './../../assets/img/obstructions-bg-75.png';

import $ from "jquery";
import * as L from "leaflet";
import "./../../helpers/leaflet-polygon.fillPattern";

import Obstructions from "../../Obstructions/Obstructions";


class ObstructionSettingsToolbar extends Component {
    constructor(props) {
        super(props);

        this.map = this.props.obstruction.map;
        this.props.obstruction.attachSettingsToolbar(this);

        this.defaults = {
            obstructionSize: 2,
            setbacksSize: 2
        };

        this.state = {
            active: true,
        };

        this.drawSetbacks = this.drawSetbacks.bind(this);
        this.removeSetbacks = this.removeSetbacks.bind(this);
        this.toggleOnmapToolbar = this.toggleOnmapToolbar.bind(this);
        this.resave = this.resave.bind(this);
        this.updateLayer = this.updateLayer.bind(this);
        this.deleteSetbacks = this.deleteSetbacks.bind(this);
        this.enableEdit = this.enableEdit.bind(this);
        this.changeHint = this.changeHint.bind(this);
        this.onObstructionsClick = this.onObstructionsClick.bind(this);
    }


    show(withResave) {
        if (withResave !== false) {
            this.resave();
        }

        this.setState({
            active: true
        });

        this.props.onDrawingChanged('obstructions');
    }


    hide() {
        this.setState({
            active: false
        });

        this.props.onDrawingChanged('');
    }


    /**
     * Add new group of solar panels
     */
    toggleOnmapToolbar(e) {
        e.preventDefault();
        this.props.onToggleOnmapToolbar();

        $(e.currentTarget).siblings('.toolbar-extending').find('.btn.active').removeClass('active');
        $(e.currentTarget).parent().siblings('.active').removeClass('active');
        $(e.currentTarget).parent().toggleClass('active');
    }


    /**
     * Add new group of solar panels
     */
    updateLayer(id, layer) {
        this.props.updateLayer(id, layer);
    }


    /**
     * Start drawing setbacks
     */
    drawSetbacks(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        let setbacks = new Obstructions(this.map, this);
        this.setbacksGroups[setbacks.id] = setbacks;
    }


    /**
     * Remove active setbacks
     */
    removeSetbacks(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        this.map.pm.enableGlobalRemovalMode();
    }


    /**
     * Delete these setbacks
     */
    deleteSetbacks(e) {
        e.preventDefault();

        this.props.setbacks.selfDelete();
    }


    /**
     * Delete these setbacks
     */
    enableEdit(e) {
        e.preventDefault();

        this.props.setbacks.layer.pm.enable({
            allowSelfIntersection: false,
        });
    }


    getGeoJSONCoordinates(geoJSON) {
        let results = [],
            rawCoordinates = geoJSON.geometry.coordinates;

        if (geoJSON.geometry.type == 'Polygon') {
            rawCoordinates = rawCoordinates[0];
        } else if (geoJSON.geometry.type == 'Point') {
            rawCoordinates = [rawCoordinates];
        }

        if (rawCoordinates.length) {
            for (const coords of rawCoordinates) {
                results.push(L.latLng(coords[1], coords[0]));
            }
        }

        return results;
    }


    createObstruction(geoJSON) {
        if (this.props.obstruction.type === 'circle') {
            this.createObstructionFromCircle();
        } else {
            const coords = this.getGeoJSONCoordinates(geoJSON);
            this.setbacksPolygon = L.polygon(coords, {fill: 'url('+obstructionsBg+')', fillColor: 'transparent', fillOpacity: 1, color: '#B42318',  weight: 2, zIndex: 2, pane: 'tilePane'}).addTo(this.map);
            this.setbacksPolygon.on('click', this.onObstructionsClick);
        }

        return true;
    }
    createObstructionFromCircle() {
        this.setbacksPolygon = L.circle(this.props.obstruction.layer.getLatLng(), {fill: 'url('+obstructionsBg+')', fillColor: 'transparent', fillOpacity: 1, color: '#B42318',  weight: 2, zIndex: 2, pane: 'tilePane', radius: this.props.obstruction.layer.getRadius()}).addTo(this.map);
        this.setbacksPolygon.on('click', this.onObstructionsClick);


        return true;
    }


    onObstructionsClick(e) {
        L.DomEvent.stopPropagation(e);

        if (!this.state.active) {this.activate();}
    }


    changeHint(text) {
        this.props.changeOnmapHint(text);
    }


    hideObstructions() {
        if (this.setbacksPolygon) {this.setbacksPolygon.removeFrom(this.map);}
    }
    activate() {
        this.props.obstruction.enable();
    }


    componentWillUnmount() {
        if (this.setbacksMainLine) {this.setbacksMainLine.remove();}
        if (this.setbacksPolygon) {this.setbacksPolygon.remove();}
        if (this.setbacksPolygonPhantom) {this.setbacksPolygonPhantom.removeFrom(this.map);}
        if (this.hoverPolyline) {this.hoverPolyline.removeFrom(this.map);}
    }


    resave() {
        let geoJSON = this.props.obstruction.layer.toGeoJSON();
        this.props.obstruction.layer.removeFrom(this.map);

        let obstruction = this.createObstruction(geoJSON);
        this.changeHint('');
    }


    render() {
        return (
            <ul className={this.state.active?'onmap-toolbar':'onmap-toolbar d-none'} data-id={this.props.obstruction.id}>
                <li className="d-none">
                    <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}><img src={imgSetObstructions} /> <span>Obstructions</span></a>
                </li>
            </ul>
        );
    }
}

export default ObstructionSettingsToolbar;
