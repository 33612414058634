import React from 'react';
import {styled, keyframes} from '@stitches/react';
import {violet, blackA, red, mauve} from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import PSSlider from "./PSSlider";
import './ShadingAlert.css';

const StyledContent = styled(AlertDialogPrimitive.Content, {
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: 'rgba(6,11,39,0.15) 0 8px 24px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '470px',
    height: 'auto',
    //maxHeight: '386px',
    zIndex: 10000,
    padding: 0,
    /*'@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },*/
    '&:focus': {outline: 'none'},
});

function Content({children, ...props}) {
    return (
        <AlertDialogPrimitive.Portal>
            <StyledContent {...props}>{children}</StyledContent>
        </AlertDialogPrimitive.Portal>
    );
}

const StyledTitle = styled(AlertDialogPrimitive.Title, {
    margin: 0,
    padding: '23px 25px',
    border: '0',
    color: '#2B2E32',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
});

const StyledDescription = styled(AlertDialogPrimitive.Description, {
    marginBottom: 0,
    padding: 25,
    borderTop: '1px #dbdcdc solid',
    borderBottom: '1px #dbdcdc solid',
    color: '#2B2E32',
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
});

// Exports
export const AlertDialog = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogContent = Content;
export const AlertDialogTitle = StyledTitle;
export const AlertDialogDescription = StyledDescription;
export const AlertDialogAction = styled(AlertDialogPrimitive.Action, {
    '&:hover': {
        background: '#2B2F33',
        borderColor: '#2B2F33'
    }
});
export const AlertDialogCancel = styled(AlertDialogPrimitive.Cancel, {
    '&:hover': {
        background: '#F9FAFB'
    }
});

// Your app...
const Flex = styled('div', {display: 'flex'});

const Button = styled('button', {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    padding: '0',
    fontSize: 16,
    lineHeight: '120%',
    fontWeight: 400,
    height: 38,
    color: '#ffffff',
    transition: 'background 500ms ease 0s',

    variants: {
        variant: {
            blue: {
                backgroundColor: 'rgb(39, 110, 250)',
                border: '1px solid rgba(43, 47, 51, 0.14)',
                width: 83,
                fontSize: 16,
                fontWeight: 500,
                color: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(43 47 51 / 20%) 0px 2px 6px 0px'
            },
            white: {
                backgroundColor: '#ffffff',
                border: '1px solid rgb(193, 196, 199)',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.05)',
                color: 'rgb(70, 77, 86)',
                width: 78,
                fontSize: 15,
                fontWeight: 500,

                '&:hover': {
                    border: '1px solid rgb(40, 110, 250)',
                    color: 'rgb(40, 110, 250)',
                }
            },
        },
    },

    defaultVariants: {
        variant: 'white',
    },
});

const ShadingAlert = (props) => {
    let minValue = 0;

    if (window.appComponent.state.project.defaultShadingAsMinimum == '1') {
        let lossesData = JSON.parse(window.appComponent.state.project.lossesData);
        if (lossesData && lossesData.Shading) {
            minValue = lossesData.Shading;
        }
    }

    let defaultValue = props.value;
    if (props.value < minValue) {defaultValue = minValue;}
    const [shadingValue, setShadingValue] = React.useState(defaultValue);

    return (
        <AlertDialog open={props.open}>
            <AlertDialogContent>
                <AlertDialogTitle>
                    <span className="shading-alert-title-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"/>
                        </svg>
                    </span>

                    <span className="shading-alert-title">
                        <span className="prior-title">Shading Values</span>
                        <span>Enter the shading value of the solar array below</span>
                    </span>

                    <button className="shading-alert-close" onClick={(e) => {e.preventDefault(); setShadingValue(props.value); props.onShadingAlertOpenChanged(false);}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </AlertDialogTitle>
                <AlertDialogDescription>
                    <span className="shading-alert-controls">
                        <a href={''} onClick={(e) => {e.preventDefault(); if (shadingValue-1 >= minValue) {setShadingValue(shadingValue-1);}}} aria-disabled={shadingValue == minValue} className={'btn-minus'}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                            </svg>
                        </a>
                        <span className={'shading-value'}>{shadingValue}</span>
                        <a href={''} onClick={(e) => {e.preventDefault(); if (shadingValue+1 <= 99) {setShadingValue(shadingValue+1);}}} aria-disabled={shadingValue == 99}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"/>
                            </svg>
                        </a>
                    </span>
                    <PSSlider
                        onChange={(values) => {setShadingValue(values[0])}}
                        defaultValue={shadingValue}
                        value={shadingValue}
                        min={minValue}
                        max={99}
                        step={1}
                        styledTrackStyles={{'&[data-orientation="horizontal"]': {height: 6}}}
                        styledThumbStyles={{width: 14, height: 14}}
                    />
                    <span className="shading-alert-slider-labels">
                        <span>No Shade {/*<PSTooltip title={'0% Shade Tip'} />*/}</span>
                        <span>50% Shade {/*<PSTooltip title={'50% Shade Tip'} />*/}</span>
                        <span>Full Shade {/*<PSTooltip title={'100% Shade Tip'} />*/}</span>
                    </span>
                </AlertDialogDescription>
                <Flex css={{justifyContent: 'flex-end', padding: '16px 25px'}}>
                    <AlertDialogCancel asChild onClick={(e) => {e.preventDefault(); setShadingValue(props.value); props.onShadingAlertOpenChanged(false);}}>
                        <Button variant="white" css={{marginRight: 7}}>
                            Cancel
                        </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild onClick={(e) => {e.preventDefault(); props.onShadingAlertValueAccepted(shadingValue);}}>
                        <Button variant="blue">Save</Button>
                    </AlertDialogAction>
                </Flex>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ShadingAlert;
