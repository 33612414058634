import imgCaret from '../../assets/img/caret.svg';

export const SelectStyles = {
    clearIndicator: (baseStyles, state) => ({
        ...baseStyles,
        //color: state.isFocused ? 'grey' : 'red',
    }),
    container: (baseStyles, state) => ({
        ...baseStyles,
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        background: '#FFFFFF',
        border: state.isFocused ? '1px #276EFA solid' : '1px solid #DBDBDB',
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.05)',
        borderRadius: '2px',
        height: '40px',
        width: '100%',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '120%',
        transition: 'border-color 0.3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        color: '#0F2154',
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
    }),
    group: (baseStyles, state) => ({
        ...baseStyles,
    }),
    groupHeading: (baseStyles, state) => ({
        ...baseStyles,
    }),
    indicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        backgroundImage: `url(${imgCaret})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 10px center',
        backgroundSize: '13px 8px',
        width: '32px',
        ' > *': {
            display: 'none !important'
        }
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: 'none'
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
    }),
    loadingIndicator: (baseStyles, state) => ({
        ...baseStyles,
    }),
    loadingMessage: (baseStyles, state) => ({
        ...baseStyles,
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        margin: '-1px 0 0 0',
        padding: 0,
        border: 0,
        boxShadow: 'none',
        borderRadius: 0,
        maxHeight: '200px',
    }),
    menuList: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        border: '1px #276EFA solid',
        maxHeight: '200px',
    }),
    menuPortal: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValue: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValueLabel: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValueRemove: (baseStyles, state) => ({
        ...baseStyles,
    }),
    noOptionsMessage: (baseStyles, state) => ({
        ...baseStyles,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0 10px',
        backgroundColor: '#FFFFFF',
        height: '40px',
        width: '100%',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '40px',
        color: '#0F2154',
        '&:hover': {
            backgroundColor: '#F9FAFB',
            color: '#0F2154',
            cursor: 'pointer'
        }
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        margin: 0,
        padding: '0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#444',
        lineHeight: '36px;'
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0 20px 0 10px'
    })
}