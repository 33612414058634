import React from 'react';

import './Loading.css';

const Loading = function (props) {
    return (
        <>
            <span className={props.visible?'opacity-0':''}>{props.label}</span>
            <div className={'lds-ring'+(props.visible?'':' d-none')}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default Loading;
