import React, {Component} from 'react';

import iconChangeLocation from './../../assets/img/toggle-shadows-icon.png';


class ToggleShadowsToolbar extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }


    onClick(e) {
        e.preventDefault();

        this.props.toggleShadowsClicked();
    }


    render() {
        return (
            <div className={'onmap-toolbar d-none'}>
                <a href="#" className="btn btn-default btn-icon btn-shading-picker" onClick={this.onClick}>
                    <img src={iconChangeLocation} className="d-inline d-sm-none" />
                    <span className="d-none d-sm-inline"><span id="mps-show">Show</span><span id="mps-hide">Hide</span> Shading Values on Hover</span>
                </a>
            </div>
        );
    }
}

export default ToggleShadowsToolbar;
