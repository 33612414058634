import React, { Component } from 'react';

import imgSetTilt from './../../assets/img/set-tilt.svg';
import imgSetShading from './../../assets/img/sun.svg';

import $ from "jquery";

import PSSlider from "../General/PSSlider";
import ShadingAlert from "../General/ShadingAlert";


class SolarPanelsSettingsToolbar extends Component {
    constructor(props) {
        super(props);

        this.map = this.props.solarPanel.map;
        this.props.solarPanel.attachSettingsToolbar(this);

        let defaultShading = 3;
        if (window.appComponent.state.project && window.appComponent.state.project.lossesData) {
            const projectLossesData = JSON.parse(window.appComponent.state.project.lossesData);
            defaultShading = projectLossesData['Shading'];
        }

        this.defaults = {
            maxSlope: 80,
            slope: 25,
            maxShading: 100,
            shading: defaultShading
        };

        //console.log(this.props.solarPanel);

        this.state = {
            activeItem: null,
            active: false,
            slope: (this.props.solarPanel.slope)?(this.props.solarPanel.slope):(this.defaults.slope),
            shading: Number.isFinite(this.props.solarPanel.shading)?(this.props.solarPanel.shading):(this.defaults.shading),
            shadingAlertOpen: this.props.solarPanel.isFresh
        };

        this.toggleOnmapToolbar = this.toggleOnmapToolbar.bind(this);
        this.onSlopeChanged = this.onSlopeChanged.bind(this);

        this.onShadingClicked = this.onShadingClicked.bind(this);
        this.onShadingChanged = this.onShadingChanged.bind(this);
        this.onShadingAlertOpenChanged = this.onShadingAlertOpenChanged.bind(this);
        this.onShadingAlertValueAccepted = this.onShadingAlertValueAccepted.bind(this);
    }


    show() {
        $('div.toolbar-extending .btn.active').removeClass('active');
        $('div.onmap-toolbar.active').removeClass('active');

        this.setState({
            active: true,
            activeItem: null
        });

        this.props.onDrawingChanged('panels');
    }


    hide() {
        $('div.toolbar-extending .btn.active').removeClass('active');
        $('div.onmap-toolbar.active').removeClass('active');

        this.setState({
            active: false,
            activeItem: null
        });

        this.props.onDrawingChanged('');
    }


    /**
     * Add new group of solar panels
     */
    toggleOnmapToolbar(e, itemKey) {
        e.preventDefault();

        if (this.state.activeItem === itemKey) {itemKey = null;}
        this.setState({
            activeItem: itemKey
        });
    }


    /**
     * Event on slope change
     */
    onSlopeChanged(value) {
        this.setState({
            slope: value[0]
        }, function() {this.props.solarPanel.slope = value[0];});
    }


    onShadingClicked(e) {
        this.toggleOnmapToolbar(e, 'shading');
        this.onShadingAlertOpenChanged(true);
    }


    /**
     * Event on shading change
     */
    onShadingChanged(value) {
        this.setState({
            shading: value[0]
        }, function() {this.props.solarPanel.shading = value[0];});
    }


    onShadingAlertValueAccepted(shadingValue) {
        //this.mapComponent.saveShadingValue(shadingValue);

        this.setState({
            active: true,
            shadingAlertOpen: false,
            shading: shadingValue
        });

        this.props.solarPanel.updateShading(shadingValue);
        this.props.onDrawingChanged('panels');
    }


    onShadingAlertOpenChanged(newOpen) {
        this.setState({
            shadingAlertOpen: newOpen
        });

        if (newOpen === false) {
            this.props.solarPanel.mapComponent.disableAll();
        }
    }


    render() {
        return (
            <ul className={this.state.active?'onmap-toolbar sp-settings':'onmap-toolbar sp-settings d-none'} data-id={this.props.solarPanel.id}>
                <li className={this.state.activeItem === 'tilt'?'active':''}>
                    <a href="#" className="btn btn-default" onClick={(e) => {this.toggleOnmapToolbar(e, 'tilt')}}><img src={imgSetTilt} /> <span>Set Tilt</span></a>

                    <div className="toolbar-extending covering-block">
                        <div className="toolbar-extension">
                            <PSSlider
                                defaultValue={this.defaults.slope}
                                value={this.state.slope}
                                min={0}
                                max={this.defaults.maxSlope}
                                step={1}
                                onChange={this.onSlopeChanged}
                            />
                            <div className="toolbar-slider-value">{this.state.slope}&nbsp;&deg;</div>
                        </div>
                    </div>
                </li>
                <li className={this.state.activeItem === 'shading'?'active':''}>
                    <a href="#" className={window.appComponent.state.preventShadingPopup?'btn btn-default d-none':'btn btn-default'} onClick={this.onShadingClicked}><img src={imgSetShading} style={{height: '21px'}} /> <span>Set Shading</span></a>

                    <ShadingAlert
                        open={this.state.shadingAlertOpen}
                        value={this.state.shading}
                        onShadingAlertOpenChanged={this.onShadingAlertOpenChanged}
                        onShadingAlertValueAccepted={this.onShadingAlertValueAccepted}
                    />
                    {/*
                    <div className="toolbar-extending covering-block">
                        <div className="toolbar-extension">
                            <PSSlider
                                defaultValue={this.defaults.shading}
                                value={this.state.shading}
                                min={0}
                                max={this.defaults.maxShading}
                                step={1}
                                onChange={this.onShadingChanged}
                            />
                            <div className="toolbar-slider-value">{this.state.shading}&nbsp;%</div>
                        </div>
                    </div>*/}
                </li>
            </ul>
        );
    }
}

export default SolarPanelsSettingsToolbar;
