import React, { Component } from 'react';
import * as L from "leaflet";

import tbDelete from "./../assets/img/tb-obstructions-delete.svg";
import tbEdit from "./../assets/img/tb-obstructions-edit.svg";

class ObstructionsToolbar {
    constructor (map, obstruction) {
        this.map = map;
        this.obstruction = obstruction;

        this.info = {
            delete: {
                marker: null,
                info: {}
            },
            edit: {
                marker: null,
                info: {}
            },
        };
    }


    /**
     * Update toolbar position
     */
    update() {
        this.updateDeleteMarker();
        this.updateEditMarker();
    }


    /**
     * Remove toolbar
     */
    remove() {
        this.removeDeleteMarker();
        this.removeEditMarker();
    }


    /**
     * Show toolbar
     */
    show() {
        this.update();
    }


    /**
     * Hide toolbar
     */
    hide() {
        this.remove();
    }


    /**
     * Get base position for a toolbar
     * @param {int} number
     * @return {L.LatLng}
     */
    getPosition(number) {
        number -= 1;
        let bounds;
        if (!this.map.hasLayer(this.obstruction.layer)) {
            this.obstruction.layer.addTo(this.map);
            bounds = this.obstruction.layer.getBounds();
            this.obstruction.layer.removeFrom(this.map);
        } else {
            bounds = this.obstruction.layer.getBounds();
        }

        let pointNE = bounds.getNorthEast(),
            pointSE = bounds.getSouthEast();
        let pointPixelsNE = this.map.latLngToContainerPoint(pointNE),
            pointPixelsSE = this.map.latLngToContainerPoint(pointSE);

        let shift = {
            x: pointPixelsNE.x + 56,
            y: pointPixelsNE.y + (pointPixelsSE.y - pointPixelsNE.y) / 2 + number * 56
        };
        return this.map.containerPointToLatLng(shift);
    }


    /**
     * Create an icon for the marker
     * @param {string} src
     * @return {L.icon}
     */
    getIcon(src) {
        let addClass = '';
        if (src.indexOf('edit') > -1) {
            addClass = 'tb-obstructions-edit';
        } else if (src.indexOf('rotate') > -1) {
            addClass = 'tb-obstructions-rotate';
        } else if (src.indexOf('delete') > -1) {
            addClass = 'tb-obstructions-delete';
        } else if (src.indexOf('drag') > -1) {
            addClass = 'tb-obstructions-drag';
        }

        return L.divIcon({
            html: '<img src="'+src+'">',
            iconAnchor: [20, 20],
            iconSize: [40, 40],
            className: "toolbar-icon "+addClass
        });
    }


    /**
     *
     *  ---  Edit marker features  ---
     *
     */


    /**
     * Update edit marker
     */
    updateEditMarker() {
        let latLng = this.getPosition(1);

        if (this.info.edit.marker == null) {
            this.initEditMarker(latLng);
        } else {
            this.info.edit.marker.setLatLng(latLng);
        }

        if (this.obstruction.active == false) {
            this.removeEditMarker();
        }
    }


    /**
     * Remove edit marker
     */
    removeEditMarker() {
        if (this.info.edit.marker != null && this.map.hasLayer(this.info.edit.marker)) {this.map.removeLayer(this.info.edit.marker);}
        this.info.edit.marker = null;
    }


    /**
     * Init edit marker
     * @param {L.LatLng} position
     */
    initEditMarker(position) {
        this.info.edit.marker = L.marker(position, {
            icon: this.getIcon(tbEdit)
        }).addTo(this.map);

        this.info.edit.marker.on('click', e => this.editMarkerEventClick(e));
    }


    /**
     * *click* event listener for edit marker
     * @param {L.Event} e
     */
    editMarkerEventClick(e) {
        this.obstruction.settingsComponent.changeHint('To finish editing, click on a selected obstruction surface.');
        this.obstruction.enableLayerEdit();
    }


    /**
     *
     *  ---  Delete marker features  ---
     *
     */


    /**
     * Init delete marker
     * @param {L.LatLng} position
     */
    initDeleteMarker(position) {
        this.info.delete.marker = L.marker(position, {
            icon: this.getIcon(tbDelete)
        }).addTo(this.map);

        this.info.delete.marker.on('click', e => this.obstruction.selfDelete());
    }


    /**
     * Update delete marker
     */
    updateDeleteMarker() {
        let point = this.getPosition(0);

        if (this.info.delete.marker == null) {
            this.initDeleteMarker(point);
        } else {
            this.info.delete.marker.setLatLng(point);
        }

        if (this.obstruction.active === false) {
            this.removeDeleteMarker();
        }
    }


    /**
     * Remove delete marker
     */
    removeDeleteMarker() {
        if (this.info.delete.marker != null && this.map.hasLayer(this.info.delete.marker)) {this.map.removeLayer(this.info.delete.marker);}
        this.info.delete.marker = null;
    }
}

export default ObstructionsToolbar;