import * as L from "leaflet";

import {DynamicGrid} from "./DynamicGrid";
import {Point} from "leaflet/src/geometry/Point";
import Map from './../Components/Map/Map';


export var SolarPanels = DynamicGrid.extend({

    initialize(mapComponent, panelData, options = {}) {
        this.mapComponent = mapComponent;
        this.isFresh = ((panelData.showShadingPopup !== undefined)?panelData.showShadingPopup:true);
        if (window.appComponent.state.preventShadingPopup) {
            this.isFresh = false;
        }

        this.wattage = panelData.wattage || 0;
        this.slope = panelData.slope || null;
        this.shading = Number.isFinite(panelData.shading) ? panelData.shading : null;
        this.systemLosses = panelData.systemLosses || null;
        this.production = panelData.production || null;

        DynamicGrid.prototype.initialize.call(this, panelData.panelSize, options);
	},


    activate() {
        if (window.appComponent.route != 'edit') {return;}

        this.mapComponent.disableAllGroups();

        if (this.settings) {
            this.settings.show();
        }

        this.mapComponent.onDrawingChanged('panels');

        DynamicGrid.prototype.activate.call(this);
    },


    deactivate() {
        if (this.getSettledTilesCount() === 0) {
            this.selfDelete();
            return;
        }

        if (this.settings) {
            this.settings.hide();
        }

        DynamicGrid.prototype.deactivate.call(this);

        this.mapComponent.onDrawingChanged('');
        this.mapComponent.panelsUpdated();
    },

    _unsettleTile(tileID) {
        DynamicGrid.prototype._unsettleTile.call(this, tileID);

        if (this.getSettledTilesCount() === 0) {
            this.selfDelete();
        }
    },


    /**
    * Init first marker placement
    */
    initFirstPanel() {
        let start = L.latLng(this._map.getCenter());
        const firstTileID = this.placeTile(start);
        this._settleTile(firstTileID);

        this.activate();
    },


    updateShading(shadingValue) {
        this.shading = shadingValue;
        this.systemLosses = this.getSystemLosses();
    },


    getSystemLosses() {
        let defaults = JSON.parse(window.appComponent.state.project.lossesData);
        if (this.shading || this.shading === 0) {
            defaults['Shading'] = this.shading;
        }

        let losses = 1;
        for (const value of Object.values(defaults)) {
            losses *= (1 - value/100);
        }
        losses = (1 - losses) * 100;

        if (losses < -5) {losses = -5;}
        if (losses > 99) {losses = 99;}
        return Math.round(losses * 100) / 100;
    },


    /**
     * Export data
     */
    export() {
        let data = {
            "array size": (this.getSettledTilesCount() * this.wattage / 1000)+"",
            quantity: this.getSettledTilesCount()+"",
            wattage: this.wattage,
            azimuth: Math.round(this.getAzimuth() * 100) / 100+"",
            slope: this.slope+""
        };

        return data;
    },


    /**
     * Save info about panel group
     */
    save() {
        if (this.shading === null) {
            let defaults = JSON.parse(window.appComponent.state.project.lossesData);
            this.shading = defaults['Shading'];
        }
        if (this.systemLosses === null) {
            this.systemLosses = this.getSystemLosses();
        }

        let azimuth = this.getAzimuth();
        if (azimuth < 0) {
            azimuth += 360;
        }

        let data = {
            orientation: ((this._panelSize[0] > this._panelSize[1])?('landscape'):('portrait')),
            shading: this.shading,
            systemLosses: this.systemLosses,
            azimuth: azimuth,
            slope: this.slope,
            panels: this.getSettledTilesLatLngs(),
            production: this.production,
        };

        return data;
    },


    //https://www.switchsolar.io/api-reference/account-api/usage-profile/#example-4---generate-a-solar-production-profile-using-the-integrated-pvwatts
    prepareDataForPVWatts(index) {
        const arraySize = this.getSettledTilesCount() * this.wattage / 1000,
            azimuth = Math.round(this.getAzimuth() * 100) / 100,
            slope = (this.slope)?(this.slope):(25);

        let totalPanels = 0;
        for (const solarPanel of Object.values(this.mapComponent.state.solarPanelGroups)) {
            totalPanels += solarPanel.getSettledTilesCount();
        }

        let inverterEfficiency = '96',
            DCACRatio = 1.2;
        if (window.appComponent.state.invertersAvailable && window.appComponent.state.project.inverter && window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]) {
            inverterEfficiency = window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['efficiency']+"";

            if (window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['is_micro_inverter']) {
                DCACRatio = this.wattage / window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['rated_power_output'];
            } else {
                DCACRatio = totalPanels * this.wattage / window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['rated_power_output'];
            }

            DCACRatio = Math.round(DCACRatio * 100) / 100;
            DCACRatio = 1.2; //TODO: hard coded default value for non-micro-inverters
        }

        return {
            "providerAccountId": window.appComponent.state.project.pvwatts.providerAccountId,
            "providerProfileId": window.appComponent.state.project.pvwatts.providerAccountId+"-pvwatts-"+index,
            "groupBy": "YEAR",
            "serviceTypes": "SOLAR_PV",
            "source": {
                "sourceId": "PVWatts",
                "sourceVersion": "6"
            },
            "properties": {
                "systemSize": {
                    "keyName": "systemSize",
                    "dataValue": arraySize
                },
                "azimuth": {
                    "keyName": "azimuth",
                    "dataValue": azimuth
                },
                "losses": {
                    "keyName": "losses",
                    "dataValue": (this.systemLosses || this.systemLosses === 0)?(this.systemLosses):(window.appComponent.state.project.losses)
                },
                "inverterEfficiency": {
                    "keyName": "inverterEfficiency",
                    "dataValue": inverterEfficiency
                },
                "tilt": {
                    "keyName": "tilt",
                    "dataValue": slope
                },
                "DCACRatio": {
                    "keyName": "DCACRatio",
                    "dataValue": DCACRatio
                },
                "moduleType": {
                    "keyName": "moduleType",
                    "dataValue": "1"
                }
            }
        };
    },


    attachSettingsToolbar(settingsComponent) {
        this.settings = settingsComponent;
    },


    /**
     * Remove current solar panel group
     */
    selfDelete() {
        DynamicGrid.prototype.selfDelete.call(this);

        this.mapComponent.deleteGroup(this.id);
    }

});