import React, { Component } from 'react';
import * as L from "leaflet";
import GeometryUtil from 'leaflet-geometryutil';
import $ from "jquery";
import PSSlider from "./../Components/General/PSSlider";

import tbEdit from "./../assets/img/tb-setbacks-edit.svg";
import tbChange from "./../assets/img/tb-setbacks-change.svg";
import tbDelete from "./../assets/img/tb-setbacks-delete.svg";
import tbRemoveSide from "./../assets/img/tb-setbacks-remove-side.svg";

class SetbacksToolbar {
    constructor (map, setbacks) {
        this.map = map;
        this.setbacks = setbacks;

        this.info = {
            delete: {
                marker: null,
                info: {}
            },
            edit: {
                marker: null,
                info: {}
            },
            change: {
                marker: null,
                info: {}
            },
        };
    }


    /**
     * Update toolbar position
     */
    update() {
        this.updateDeleteMarker();
        this.updateEditMarker();
        this.updateChangeMarker();
    }


    /**
     * Remove toolbar
     */
    remove() {
        this.removeDeleteMarker();
        this.removeEditMarker();
        this.removeChangeMarker();
    }


    /**
     * Show toolbar
     */
    show() {
        this.update();
    }


    /**
     * Hide toolbar
     */
    hide() {
        this.remove();
    }


    /**
     * Get base position for a toolbar
     * @param {int} number
     * @return {L.LatLng}
     */
    getPosition(number) {
        number -= 1;
        const bounds = this.setbacks.layer.getBounds();

        let pointNE = bounds.getNorthEast(),
            pointSE = bounds.getSouthEast();
        let pointPixelsNE = this.map.latLngToContainerPoint(pointNE),
            pointPixelsSE = this.map.latLngToContainerPoint(pointSE);

        let shift = {
            x: pointPixelsNE.x + 56,
            y: pointPixelsNE.y + (pointPixelsSE.y - pointPixelsNE.y) / 2 + number * 56
        };
        return this.map.containerPointToLatLng(shift);
    }


    /**
     * Create an icon for the marker
     * @param {string} src
     * @return {L.icon}
     */
    getIcon(src) {
        let addClass = '';
        if (src.indexOf('edit') > -1) {
            addClass = 'tb-setbacks-edit';
        } else if (src.indexOf('change') > -1) {
            addClass = 'tb-setbacks-change tb-setbacks-change-'+this.setbacks.id;
        } else if (src.indexOf('delete') > -1) {
            addClass = 'tb-setbacks-delete';
        }

        return L.divIcon({
            html: '<img src="'+src+'">',
            iconAnchor: [20, 20],
            iconSize: [40, 40],
            className: "toolbar-icon "+addClass
        });
    }


    /**
     *
     *  ---  Edit marker features  ---
     *
     */


    /**
     * Update edit marker
     */
    updateEditMarker() {
        let latLng = this.getPosition(1);

        if (this.info.edit.marker == null) {
            this.initEditMarker(latLng);
        } else {
            this.info.edit.marker.setLatLng(latLng);
        }

        if (this.setbacks.active == false) {
            this.removeEditMarker();
        }
    }


    /**
     * Remove edit marker
     */
    removeEditMarker() {
        if (this.info.edit.marker != null && this.map.hasLayer(this.info.edit.marker)) {this.map.removeLayer(this.info.edit.marker);}
        this.info.edit.marker = null;
    }


    /**
     * Init edit marker
     * @param {L.LatLng} position
     */
    initEditMarker(position) {
        this.info.edit.marker = L.marker(position, {
            icon: this.getIcon(tbEdit)
        }).addTo(this.map);

        this.info.edit.marker.on('click', e => this.editMarkerEventClick(e));
    }


    /**
     * *click* event listener for edit marker
     * @param {L.Event} e
     */
    editMarkerEventClick(e) {
        this.setbacks.settingsComponent.changeHint('To finish editing, click on a selected roof surface.');
        this.setbacks.enableLayerEdit();
    }


    /**
     *
     *  ---  Change marker features  ---
     *
     */


    /**
     * Update change marker
     */
    updateChangeMarker() {
        let latLng = this.getPosition(0);

        if (this.info.change.marker == null) {
            this.initChangeMarker(latLng);
        } else {
            this.info.change.marker.setLatLng(latLng);
        }

        if (this.setbacks.active == false) {
            this.removeChangeMarker();
        }
    }


    /**
     * Remove change marker
     */
    removeChangeMarker() {
        if (this.info.change.marker != null && this.map.hasLayer(this.info.change.marker)) {
            this.map.removeLayer(this.info.change.marker);
        }
        this.info.change.marker = null;
    }


    /**
     * Init change marker
     * @param {L.LatLng} point
     */
    initChangeMarker(point) {
        let that = this;

        this.info.change.marker = L.marker(point, {
            icon: this.getIcon(tbChange)
        }).addTo(this.map);

        this.info.change.marker.on('click', e => this.changeMarkerEventClick(e));
    }


    /**
     * *click* event listener for change marker
     * @param {L.Event} e
     */
    changeMarkerEventClick(e) {
        this.setbacks.settingsComponent.changeHint('');
        $('.tb-setbacks-change-'+this.setbacks.id).toggleClass('active');
        this.setbacks.settingsComponent.resave();
    }


    /**
     *
     *  ---  Delete marker features  ---
     *
     */


    /**
     * Init delete marker
     * @param {L.LatLng} position
     */
    initDeleteMarker(position) {
        this.info.delete.marker = L.marker(position, {
            icon: this.getIcon(tbDelete)
        }).addTo(this.map);

        this.info.delete.marker.on('click', e => this.setbacks.selfDelete());
    }


    /**
     * Update delete marker
     */
    updateDeleteMarker() {
        let point = this.getPosition(2);

        if (this.info.delete.marker == null) {
            this.initDeleteMarker(point);
        } else {
            this.info.delete.marker.setLatLng(point);
        }

        if (this.setbacks.active == false) {
            this.removeDeleteMarker();
        }
    }


    /**
     * Remove delete marker
     */
    removeDeleteMarker() {
        if (this.info.delete.marker != null && this.map.hasLayer(this.info.delete.marker)) {this.map.removeLayer(this.info.delete.marker);}
        this.info.delete.marker = null;
    }
}

export default SetbacksToolbar;