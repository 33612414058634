import * as L from "leaflet";
import ObstructionsToolbar from "./ObstructionsToolbar";

import './Obstructions.css';


class Obstructions {
    constructor (map, mapper, id, type) {
        this.id = ((id)?(id):('ID-' + Math.round(Math.random() * 10000000)));
        this.map = map;
        this.mapper = mapper;
        this.toolbar = null;

        this.layer = null;
        this.settings = null;
        this.active = true;
        this.initComplete = false;

        this.type = type;

        this.obstructions = [];

        this.drawingOptions = {
            snappable: true,
            snapDistance: 20,
            allowSelfIntersection: false,
            removeVertexOn: 'dblclick',
            tooltips: false,
            pathOptions: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
                pane: 'tilePane'
            },
            templineStyle: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
            },
            hintlineStyle: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
            },
        };

        this.createLayer = this.createLayer.bind(this);
        this.activateLayer = this.activateLayer.bind(this);
        this.onboardingHints = this.onboardingHints.bind(this);
        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
        this.export = this.export.bind(this);

        if (!id) {
            this.init(type);
        }
    }


    passiveInit(loadingData) {
        let coords = [],
            rawCoordinates = loadingData.mainLine.geometry.coordinates;

        if (loadingData.type) {
            this.type = loadingData.type;
        } else {
            this.type = 'polygon';
        }

        this.initComplete = true;

        if (this.type !== 'circle') {
            if (rawCoordinates.length) {
                for (const coordsRaw of rawCoordinates[0]) {
                    coords.push(L.latLng(coordsRaw[1], coordsRaw[0]));
                }
            }
            this.layer = L.polygon(coords).addTo(this.map);
        } else {
            let circleOptions = JSON.parse(JSON.stringify(this.drawingOptions.pathOptions));
            circleOptions['radius'] = loadingData.radius;
            this.layer = L.circle(L.latLng(rawCoordinates[1], rawCoordinates[0]), circleOptions).addTo(this.map);
        }
        this.layer.pm.enable(this.drawingOptions);

        this.layer.on('click', this.activateLayer);

        this.toolbar = new ObstructionsToolbar(this.map, this);

        this.activateLayer();
    }


    /**
     * Entry point
     */
    init(type) {
        this.map.on('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', this.onboardingHints);
        });

        let pmType = 'Polygon';
        switch (type) {
            case 'rectangle': pmType = 'Rectangle'; break;
            case 'circle': pmType = 'Circle'; break;
        }

        this.map.pm.enableDraw(pmType, this.drawingOptions);

        this.map.on('pm:create', this.createLayer);
    }


    onboardingHints(e) {
        switch (e.workingLayer._latlngs.length) {
            case 1: this.settingsComponent.changeHint('Click to continue drawing. Hold ALT key to disable snapping.'); break;
            case 3: this.settingsComponent.changeHint('Click the first marker to finish'); break;
        }
    }


    /**
     * Set current setbacks as active
     */
    enable() {
        this.mapper.disableAllObstructions();

        this.active = true;

        this.settingsComponent.show(false);
        this.toolbar.show();

        this.map.on('click', this.disable);
    }


    /**
     * Set current setbacks as NOT active
     */
    disable() {
        if (!this.initComplete) {
            this.selfDelete();
        } else {
            this.active = false;

            if (this.settingsComponent) {this.settingsComponent.hide();}
            if (this.toolbar) {this.toolbar.hide();}

            this.map.off('click', this.disable);
            this.map.pm.disableDraw();
        }

        this.map.off('pm:create', this.createLayer);
    }


    attachSettingsToolbar(settingsComponent) {
        this.settingsComponent = settingsComponent;
        this.settingsComponent.changeHint('Click to place the first vertex');
    }


    createLayer(e) {
        this.layer = e.layer;
        this.layer.addTo(this.map);

        this.layer.on('click', this.activateLayer);
        this.layer.on('pm:vertexremoved', this.vertexRemoved);

        this.toolbar = new ObstructionsToolbar(this.map, this);

        this.activateLayer();
        this.layer.removeFrom(this.map);
    }


    vertexRemoved(e) {
        if (e.layer.getLatLngs()[0].length !== 0) {return;}

        this.selfDelete();
    }


    activateLayer() {
        this.enableLayerEdit();

        this.settingsComponent.show();
        this.toolbar.show();
        this.map.on('click', this.disable);
        this.initComplete = true;
    }


    enableLayerEdit() {
        this.layer.addTo(this.map);

        this.layer.pm.enable({
            allowSelfIntersection: false,
        });

        this.settingsComponent.hideObstructions();
    }


    export() {
        if (!this.settingsComponent.setbacksPolygon) {return null;}

        let data = {
            mainLine: this.settingsComponent.setbacksPolygon.toGeoJSON(),
            type: this.type
        };

        if (this.type === 'circle') {
            data['radius'] = this.layer.getRadius();
        }

        return data;
    }


    /**
     * Remove current obstruction
     */
    selfDelete() {
        this.map.pm.disableDraw();
        this.settingsComponent.changeHint('');

        this.map.off('pm:create', this.createLayer);

        if (this.layer) {this.layer.remove();}

        if (this.settingsComponent) {this.settingsComponent.hide();}
        if (this.toolbar) {this.toolbar.hide();}

        this.mapper.deleteObstructions(this.id);
    }
}

export default Obstructions;