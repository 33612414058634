import React, { Component } from 'react';
import MapContainer from './MapContainer';

import imgCompass from './../../assets/img/compass.svg';
import imgAddPanel from './../../assets/img/add-panel.svg';
import parser from 'html-react-parser';
import imgRemoveAll from './../../assets/img/remove-all.svg';
import imgDrawSetbacks from './../../assets/img/draw-setbacks.svg';
import imgDrawObstructions from './../../assets/img/draw-obstructions.svg';
import imgInitialLocationPin from './../../assets/img/initial-location-pin.png';
import {SolarPanels} from "../../SolarPanels/SolarPanels";

import $ from "jquery";
import * as L from "leaflet";
import MapHelper from "../../helpers/MapHelper";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import Setbacks from "../../Setbacks/Setbacks";
import SetbackSettingsToolbar from "./SetbackSettingsToolbar";

import Obstructions from "../../Obstructions/Obstructions";
import ObstructionSettingsToolbar from "./ObstructionSettingsToolbar";
import SolarPanelsSettingsToolbar from "./SolarPanelsSettingsToolbar";
import ChangeLocationToolbar from "./ChangeLocationToolbar";
import ToggleShadowsToolbar from "./ToggleShadowsToolbar";
import NearmapsOverlay from "../../SolarPanels/NearmapsOverlay";
import PSAlert from "../General/PSAlert";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [13, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            center: props.center,
            mapType: props.mapType,
            data: [],
            solarPanelGroups: {},
            setbacksGroups: {},
            obstructionsGroups: {},
            onmapHint: '',
            drawingActive: '',
            locationChanging: false,
            colorBasedShading: null,
            shadeAnalysisOn: false,
            colorBasedShadingX: 0,
            colorBasedShadingY: 0,
            removeAllShown: false,
            removeAllProcessing: false,
        };

        this.map = null;
        this.passive = true;

        this.helper = new MapHelper(this.map);

        this.initLocationMarker = null;

        this.onMapInitialized = this.onMapInitialized.bind(this);
        this.onCenterChanged = this.onCenterChanged.bind(this);
        this.toggleOnmapToolbar = this.toggleOnmapToolbar.bind(this);
        this.onToggleOnmapToolbar = this.onToggleOnmapToolbar.bind(this);

        this.createSolarPanels = this.createSolarPanels.bind(this);
        this.toggleShadeAnalysis = this.toggleShadeAnalysis.bind(this);
        this.onFirstSolarPanelPlaced = this.onFirstSolarPanelPlaced.bind(this);
        this.saveShadingValue = this.saveShadingValue.bind(this);

        this.drawSetbacks = this.drawSetbacks.bind(this);
        this.removeSetbacks = this.removeSetbacks.bind(this);
        this.loadSetbacks = this.loadSetbacks.bind(this);
        this.updateSetbacksLayer = this.updateSetbacksLayer.bind(this);

        this.drawObstructions = this.drawObstructions.bind(this);
        this.removeObstructions = this.removeObstructions.bind(this);
        this.updateObstructionsLayer = this.updateObstructionsLayer.bind(this);

        this.testLog = this.testLog.bind(this);
        this.setOnmapHint = this.setOnmapHint.bind(this);
        this.onDrawingChanged = this.onDrawingChanged.bind(this);

        this.escFunction = this.escFunction.bind(this);

        this.repositionInitLocationMarker = this.repositionInitLocationMarker.bind(this);
        this.locationMarkerStartMoving = this.locationMarkerStartMoving.bind(this);
        this.locationMarkerEndMoving = this.locationMarkerEndMoving.bind(this);
        this.toggleInitLocationMarker = this.toggleInitLocationMarker.bind(this);
        this.onInitLocationMarkerPopupClick = this.onInitLocationMarkerPopupClick.bind(this);
        this.onToggleShadowsClicked = this.onToggleShadowsClicked.bind(this);

        this.showRemoveAllModal = this.showRemoveAllModal.bind(this);
        this.removeAllShown = this.removeAllShown.bind(this);
    }


    saveShadingValue(shadingValue) {
        if (this.state.solarPanelGroups != {}) {
            for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                if (solarPanel._active) {
                    this.state.solarPanelGroups[id].saveFirstPanel(shadingValue);
                }
            }
        }
    }


    onDrawingChanged(newDrawingType) {
        if (this.state.drawingActive === 'loading' && newDrawingType.length) {return;}

        if (window.appComponent.state.onmapPanelIdle === true && newDrawingType === '' && this.state.drawingActive === 'panels') {
            window.appComponent.setState({
                onmapPanelIdle: false
            });
        }

        this.setState({
            drawingActive: newDrawingType
        });

        if (newDrawingType === 'setbacks') {
            this.disableAllGroups();
            this.disableAllObstructions();
            this.hideInitLocationMarker();
        } else if (newDrawingType === 'panels') {
            this.disableAllSetbacks();
            this.disableAllObstructions();
            this.hideInitLocationMarker();

            window.appComponent.setState({
                onmapPanelIdle: true
            });
        } else if (newDrawingType === 'obstructions') {
            this.disableAllGroups();
            this.disableAllSetbacks();
            this.hideInitLocationMarker();
        }
    }


    setOnmapHint(text) {
        this.setState({
            onmapHint: text
        });
    }


    onMapInitialized(map) {
        this.map = map;

        this.map.addEventListener('click', (event) => this.cancelEditMode(event));

        this.initShadeAnalysis();

        this.props.projectInfoReady.then((projectInfo) => {
            if (!projectInfo.data || !projectInfo.data.length) {
                this.createInitLocationMarker();
            }

            this.renderLoadedData(projectInfo);
        });
    }


    createInitLocationMarker() {
        if (window.appComponent.route !== 'edit') {return;}
        this.disableAll();
        //this.setOnmapHint('Please drag the marker to center the map, then click "Save" to exit and save your changes.');

        this.initLocationMarker = L.marker(this.map.getCenter(), {
            icon: new L.icon({
                iconUrl: imgInitialLocationPin,
                /*iconSize: [34, 42],
                iconAnchor: [15, 35],*/
                iconSize: [30, 40],
                iconAnchor: [15, 40],
                tooltipAnchor: [0, -37],
            }),
            draggable: false
        }).addTo(this.map)/*.bindTooltip("This location will be used as a center.", {direction: "top"}).openTooltip()*/;

        if (this.state.locationChanging) {
            let popupContent = document.createElement('a');
            popupContent.href = '#';
            popupContent.className = 'btn btn-default';
            popupContent.innerHTML = '<span class="onmap-position-regular-label">Save</span><span class="onmap-position-loading-label"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></span><span class="onmap-position-processed-label text-primary">Saved</span>';
            popupContent.innerHTML += '<span class="onmap-position-hint">Please drag the marker to center the map, then click "Save" to exit and save your changes.</span>';
            popupContent.addEventListener('click', this.onInitLocationMarkerPopupClick);

            this.initLocationMarker.bindPopup(popupContent, {
                closeButton: false,
                closeOnClick: false,
                className: 'init-location-marker-popup',
                offset: [0, -35]
            });
            this.initLocationMarker.on('move', () => {
                //this.initLocationMarker.openPopup();
                //console.log('move');
            });
        }

        this.showInitLocationMarker();
    }
    onInitLocationMarkerPopupClick(e) {
        e.preventDefault();
        document.querySelector('.init-location-marker-popup').classList.add('processing');

        let center = this.map.getCenter();
        setTimeout(() => {
            window.appComponent.apiHelper.saveNewCenter(center, this.state.mapType).then((result) => {
                if (result && result.bbox) {
                    this.props.onNearmapDataChanged(result);
                }
                document.querySelector('.init-location-marker-popup').classList.remove('processing');
                document.querySelector('.init-location-marker-popup').classList.add('processed');
                setTimeout(() => {
                    document.querySelector('.init-location-marker-popup').classList.remove('processed');
                    this.toggleInitLocationMarker();
                }, 1000);
            });
        }, 300);
        /*this.setState({
            locationChanging: false,
            center: center,
            onmapHint: ''
        }, () => {
            this.props.updateCenter(center);

            if (this.initLocationMarker) {
                this.initLocationMarker.remove();
                this.map.off('move', this.repositionInitLocationMarker);
                this.map.off('movestart', this.locationMarkerStartMoving);
                this.map.off('moveend', this.locationMarkerEndMoving);

                this.initLocationMarker = null;
            }

            this.onCenterChanged(center);
        });*/
    }
    locationMarkerStartMoving() {
        if (this.initLocationMarker) {
            this.initLocationMarker.closePopup();
        }
    }
    locationMarkerEndMoving() {
        if (this.initLocationMarker) {
            this.initLocationMarker.openPopup();
        }
    }
    showInitLocationMarker() {
        this.initLocationMarker.addTo(this.map);
        this.map.on('move', this.repositionInitLocationMarker);
        this.map.on('movestart', this.locationMarkerStartMoving);
        this.map.on('moveend', this.locationMarkerEndMoving);

        this.initLocationMarker.openPopup();
    }
    repositionInitLocationMarker(e) {
        let center = this.map.getCenter();
        this.initLocationMarker.setLatLng(center);
    }
    hideInitLocationMarker() {
        if (this.state.locationChanging) {
            this.setState({
                //center: this.oldCenter,
                locationChanging: false,
                onmapHint: ''
            }, () => {
                //this.props.updateCenter(this.oldCenter);

                if (this.initLocationMarker) {
                    this.initLocationMarker.remove();
                    this.map.off('move', this.repositionInitLocationMarker);
                    this.map.off('movestart', this.locationMarkerStartMoving);
                    this.map.off('moveend', this.locationMarkerEndMoving);

                    this.initLocationMarker = null;
                }
            });
        } else {
            if (this.initLocationMarker) {
                this.initLocationMarker.remove();
                this.map.off('move', this.repositionInitLocationMarker);

                this.initLocationMarker = null;
                this.setOnmapHint('');

                this.onCenterChanged(this.map.getCenter());
            }
        }
    }
    toggleInitLocationMarker() {
        if (!this.initLocationMarker) {
            this.setState({
                locationChanging: true
            }, () => {
                this.oldCenter = L.latLng({lat: window.appComponent.state.project.lat, lng: window.appComponent.state.project.lng});
                this.createInitLocationMarker();
            });
        } else {
            this.setState({
                //center: this.oldCenter,
                locationChanging: false,
                onmapHint: ''
            }, () => {
                //this.props.updateCenter(this.oldCenter);

                if (this.initLocationMarker) {
                    this.initLocationMarker.remove();
                    this.map.off('move', this.repositionInitLocationMarker);
                    this.map.off('movestart', this.locationMarkerStartMoving);
                    this.map.off('moveend', this.locationMarkerEndMoving);

                    this.initLocationMarker = null;
                }
            });
        }
    }


    onToggleShadowsClicked() {
        this.enableDetectingColor();
    }


    goToLatLng(lat, lng) {
        const latLng = L.latLng(lat, lng);
        this.map.setView(latLng, this.map.getZoom());
    }


    onCenterChanged(center) {
        this.setState({
            center: center
        }, function() {
            this.props.updateCenter(center);
        });
    }


    initShadeAnalysis() {
        this.mapPS = L.map('mapContainer-project-sunroof', {
            zoomControl: false,
            attributionControl: false,
        });
        this.projectSunroofLayer = L.tileLayer('https://storage.googleapis.com/solar_tiles_all_2018q2/tile_{x}_{y}_{z}.png', {
            attribution: '',
            tileSize: 256,
            defaultZoomLevel: 20,
            minZoom: 20,
            maxZoom: 20
        });
        this.projectSunroofLayer.addTo(this.mapPS);
    }


    updateTmpCanvasPS(imageUrl) {
        if (this.tmpCanvasImgUrl !== imageUrl) {
            this.tmpCanvasImgUrl = imageUrl;

            if (!this.tmpCanvasPS) {
                this.tmpCanvasPS = document.createElement('canvas');
                this.tmpCanvasPS.id = 'tmp-canvas-ps';
                this.tmpCanvasPS.width = 256;
                this.tmpCanvasPS.height = 256;
                this.tmpCanvasPS.style.position = 'absolute';
                this.tmpCanvasPS.style.top = '0';
                this.tmpCanvasPS.style.left = '0';
                this.tmpCanvasPS.style.zIndex = '9999999';
                this.tmpCanvasPSContext = this.tmpCanvasPS.getContext('2d');

                document.querySelector('#mapContainer-project-sunroof').appendChild(this.tmpCanvasPS);
            }

            fetch(imageUrl, {
                mode: 'no-cors', // CORS mode
            })
                .then(response => response.blob())
                .then(blob => {
                    let img = new Image();
                    img.crossOrigin = "anonymous";
                    img.onload = () => {
                        this.tmpCanvasPSContext.clearRect(0, 0, this.tmpCanvasPS.width, this.tmpCanvasPS.height);
                        this.tmpCanvasPSContext.drawImage(img, 0, 0);
                    };
                    img.src = imageUrl;
                })
                .catch(error => console.error('Error:', error));
        }
    }


    getCanvasPSColor(x, y) {
        // Inside the img.onload function, after drawing the image
        const pixel = this.tmpCanvasPSContext.getImageData(x, y, 1, 1).data;
        //console.log(pixel);
        const rgba = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`;

        return rgba;
    }


    enableDetectingColor() {
        this.colorDetecting = false;

        if (this.state.colorBasedShading !== null) {
            this.setState({
                colorBasedShading: null,
                colorBasedShadingX: 0,
                colorBasedShadingY: 0
            });

            $('.map-panel').removeClass('shading-active');
            this.mapPS.off('mousemove');
        } else {
            $('.map-panel').addClass('shading-active');

            this.mapPS.on('mousemove', (event) => {

                // Get the geographical coordinates of the cursor
                var lat = event.latlng.lat;
                var lng = event.latlng.lng;

                // Get the current zoom level
                var zoom = this.mapPS.getZoom();


                // Calculate the tile coordinates
                var x = Math.floor((lng + 180) / 360 * Math.pow(2, zoom));
                var y = Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom));

                // Construct the tile URL
                var tileUrl = this.projectSunroofLayer._url
                    .replace('{z}', zoom)
                    .replace('{x}', x)
                    .replace('{y}', y);

                let curSelector = 'img[src="' + tileUrl + '"]';
                //get rectangle coordinates
                let rect = document.querySelector(curSelector).getBoundingClientRect();

                let relX = event.originalEvent.clientX - rect.left,
                    relY = event.originalEvent.clientY - rect.top;

                if (!this.colorDetecting) {
                    this.colorDetecting = true;

                    let colorDetected = window.appComponent.apiHelper.getPixelColor(tileUrl, relX, relY);
                    colorDetected.then((detectedResults) => {
                        //console.log(detectedResults);
                        if (detectedResults) {
                            this.setState({
                                colorBasedShading: detectedResults.position
                            }, () => {
                                this.colorDetecting = false;
                            });
                        } else {
                            this.colorDetecting = false;
                        }
                    });
                }

                this.setState({
                    colorBasedShadingX: event.containerPoint.x,
                    colorBasedShadingY: event.containerPoint.y
                });
            });
        }
    }


    toggleShadeAnalysis(e) {
        this.setState({
            shadeAnalysisOn: !this.state.shadeAnalysisOn
        }, () => {
            if (this.state.shadeAnalysisOn) {
                //document.querySelector('.toggle-shadow-toolbar-container').classList.remove('d-none');
            } else {
                //document.querySelector('.toggle-shadow-toolbar-container').classList.add('d-none');
            }

            $('#mapContainer').toggleClass('d-none');
            $('#mapContainer-project-sunroof').toggleClass('d-none');
            $('.onmap-toolbars-cover:not(.toggle-shadow-toolbar-container)').removeClass('d-none');

            if (this.state.shadeAnalysisOn) {
                this.mapPS.setView(this.map.getCenter(), 20);

                $('.onmap-toolbars-cover:not(.toggle-shadow-toolbar-container)').addClass('d-none');
            }
        });

    }


    onDataChanged(data) {
        this.props.onDataChanged(data);
    }


    renderLoadedData(rawData) {
        try {
            let panelTypeInfo = window.appComponent.state.panelTypesAvailable[window.appComponent.state.project.panelType];

            if (rawData.data) {
                this.solarPanelGroups = {};

                for (const panelData of rawData.data) {
                    let newPanelsGroup = this.addGroup(panelData, panelTypeInfo);
                    this.solarPanelGroups[newPanelsGroup.id] = newPanelsGroup;
                }

                this.setState({
                    solarPanelGroups: this.solarPanelGroups
                }, () => {
                    this.disableAllGroups();
                    window.appComponent.repopulateFromSolarPanels(this.solarPanelGroups);
                });
            }

            if (
                !rawData.data ||
                rawData.lat && rawData.lng
            ) {this.hideLoader();}

            if (rawData && rawData.additional_info) {
                this.loadSetbacks(rawData.additional_info);
            }
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }
    }


    /**
     * Add new group of solar panels
     */
    addGroup(panelData, panelTypeInfo) {
        //console.log('%cpanelData', 'color: #00f', panelData);

        if (
            panelTypeInfo.width > panelTypeInfo.height && panelData.orientation === 'portrait' ||
            panelTypeInfo.width < panelTypeInfo.height && panelData.orientation === 'landscape'
        ) {
            let tmp = panelTypeInfo.height;
            panelTypeInfo.height = panelTypeInfo.width;
            panelTypeInfo.width = tmp;
        }

        let solarPanelInitData = {
            panelSize: [panelTypeInfo.width, panelTypeInfo.height],
            wattage: panelTypeInfo.wattage,
            slope: panelData.slope,
            shading: panelData.shading,
            systemLosses: panelData.systemLosses,
            production: panelData.production,
            showShadingPopup: false
        };

        let solarPanels = new SolarPanels(this, solarPanelInitData);
        solarPanels.addTo(this.map);

        /*** --- add panels --- ***/

        //add first panel
        let firstSettled = L.latLng(panelData.panels[0]);
        const firstTileID = solarPanels.placeTile(firstSettled);
        solarPanels._settleTile(firstTileID);

        //apply azimuth
        solarPanels.rotate(panelData.azimuth);


        //add other panels
        for (let i = 1; i < panelData.panels.length; i++) {
            let latLng = L.latLng(panelData.panels[i]);
            let tileID = solarPanels._getTileIDByLatLng(latLng);
            if (!tileID) {
                tileID = solarPanels.placeTile(latLng);
            }
            solarPanels._settleTile(tileID);
        }

        /*** --- finalize creation --- ***/

        solarPanels.deactivate();

        return solarPanels;
    }


    /**
     * Disable all groups of solar panels
     */
    disableAllGroups() {
        if (this.state.solarPanelGroups != {}) {
            for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                if (solarPanel._active) {
                    solarPanel.deactivate();
                }
            }
        }
    }


    /**
     * Cancel edit mode for active solar panels group
     */
    cancelEditMode(e) {
        if (e.originalEvent.target == this.map.getContainer()) {
            if (this.passive && this.state.solarPanelGroups != {}) {
                for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                    if (solarPanel._active) {
                        this.state.solarPanelGroups[id].deactivate();
                    }
                }
            }
            this.onDrawingChanged('');

            this.collapseToolbars();
        }
    }


    /**
     * Hide rotation UI elements
     */
    collapseToolbars() {
        $('.onmap-toolbar').removeClass('active');
    }


    /**
     * Event is fired when first panel in a new array is placed
     */
    onFirstSolarPanelPlaced() {
        this.props.showShadingAlert(true);
    }


    /**
     * Create new Solar Panels array
     */
    createSolarPanels(e, type) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');
        this.disableAll();


        let solarPanelData = window.appComponent.state.panelTypesAvailable[window.appComponent.state.project.panelType];
        let solarPanelSizeArray = [solarPanelData.width, solarPanelData.height];

        if (
            solarPanelData.width < solarPanelData.height && type === 'landscape' ||
            solarPanelData.width > solarPanelData.height && type === 'portrait'
        ) {
            solarPanelSizeArray = [solarPanelData.height, solarPanelData.width];
        }

        solarPanelData['panelSize'] = solarPanelSizeArray;

        let firstPanelOnly = new SolarPanels(this, solarPanelData);
        firstPanelOnly.addTo(this.map);
        this.setState({
            solarPanelGroups: {
                ...this.state.solarPanelGroups,
                [firstPanelOnly.id]: firstPanelOnly
            }
        });

        firstPanelOnly.initFirstPanel();

        this.collapseToolbars();
        this.onFirstSolarPanelPlaced();
    }


    /**
     * Start drawing setbacks
     */
    drawSetbacks(e) {
        e.preventDefault();

        let shouldCreateNew = true;
        if (Object.keys(this.state.setbacksGroups).length) {
            for (const [id, setbacksGroup] of Object.entries(this.state.setbacksGroups)) {
                if (setbacksGroup.active && !setbacksGroup.initComplete) {
                    shouldCreateNew = false;
                }

                if (setbacksGroup.active) {
                    this.state.setbacksGroups[id].disable();
                }
            }
        }

        this.toggleOnmapToolbar(e);

        let newSetbacksGroups = this.state.setbacksGroups;

        if (shouldCreateNew) {
            $(e.currentTarget).siblings('.btn').removeClass('active');
            $(e.currentTarget).addClass('active');

            let setbacks = new Setbacks(this.map, this);

            newSetbacksGroups[setbacks.id] = setbacks;
        }

        this.setState({
            setbacksGroups: newSetbacksGroups
        });
    }


    updateSetbacksLayer(id, layer) {
        this.setState({
            setbacksGroups: (this.state.setbacksGroups[id].layer = layer)
        });
    }


    /**
     * Remove active setbacks
     */
    removeSetbacks(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        this.map.pm.enableGlobalRemovalMode();
    }


    /**
     * Delete specific group of solar panels
     * @param {string} id group ID
     */
    deleteGroup(id) {
        let solarPanelGroups = this.state.solarPanelGroups;
        delete solarPanelGroups[id];

        this.setState({
            solarPanelGroups: solarPanelGroups
        }, this.panelsUpdated);
    }


    /**
     * Delete specific setbacks
     * @param {string} id setbacks ID
     */
    deleteSetbacks(id) {
        let setbacksGroups = this.state.setbacksGroups;
        delete setbacksGroups[id];
        this.setState({
            setbacksGroups: setbacksGroups
        });

        /*if (setbacksGroups === {}) {
            this.showInitLocationMarker();
        }*/
    }


    /**
     * Disable all setbacks
     */
    disableAllSetbacks() {
        if (this.state.setbacksGroups != {}) {
            for (const [id, setbacksGroup] of Object.entries(this.state.setbacksGroups)) {
                if (setbacksGroup.active) {
                    this.state.setbacksGroups[id].disable();
                }
            }
        }

        //this.onDrawingChanged('');
    }


    /**
     * Load setbacks on init
     */
    loadSetbacks(rawData) {
        if (this.props.route != 'edit') {return;}
        let newSetbacksGroups = {}, newObstructionsGroups = {};

        if (rawData.setbacks && rawData.setbacks !== {}) {
            for (const [id, setbacksInfo] of Object.entries(rawData.setbacks)) {
                let setbacks = new Setbacks(this.map, this, id, setbacksInfo);

                newSetbacksGroups[id] = setbacks;
            }
        }

        if (rawData.obstructions && rawData.obstructions !== {}) {
            for (const [id, obstructionsInfo] of Object.entries(rawData.obstructions)) {
                let obstructions = new Obstructions(this.map, this, id, obstructionsInfo);

                newObstructionsGroups[id] = obstructions;
            }
        }

        this.setState({
            drawingActive: 'loading',
            setbacksGroups: newSetbacksGroups,
            obstructionsGroups: newObstructionsGroups
        }, function() {
            if (this.state.setbacksGroups && this.state.setbacksGroups !== {}) {
                for (const [id, setbacksInfo] of Object.entries(this.state.setbacksGroups)) {
                    let loadingData = rawData.setbacks[id];

                    this.state.setbacksGroups[id].passiveInit(loadingData);
                    this.state.setbacksGroups[id].disable();
                }
            }

            if (this.state.obstructionsGroups && this.state.obstructionsGroups !== {}) {
                for (const [id, obstructionsInfo] of Object.entries(this.state.obstructionsGroups)) {
                    let loadingData = rawData.obstructions[id];

                    this.state.obstructionsGroups[id].passiveInit(loadingData);
                    this.state.obstructionsGroups[id].disable();
                }
            }

            this.onDrawingChanged('');
        });
    }


    /**
     * Start drawing an obstruction
     */
    drawObstructions(e, type) {
        e.preventDefault();

        //let shouldCreateNew = true;
        if (this.state.obstructionsGroups !== {}) {
            for (const [id, obstructionsGroup] of Object.entries(this.state.obstructionsGroups)) {
                /*if (obstructionsGroup.active && !obstructionsGroup.initComplete) {
                    shouldCreateNew = false;
                }*/

                if (obstructionsGroup.active) {
                    this.state.obstructionsGroups[id].disable();
                }
            }
        }

        let newObstructionsGroups = this.state.obstructionsGroups;

        //shouldCreateNew = true;
        //if (shouldCreateNew) {
            $(e.currentTarget).siblings('.btn').removeClass('active');
            $(e.currentTarget).addClass('active');

            let obstructions = new Obstructions(this.map, this, null, type);

            newObstructionsGroups[obstructions.id] = obstructions;
        //}

        this.setState({
            obstructionsGroups: newObstructionsGroups
        });
    }


    updateObstructionsLayer(id, layer) {
        this.setState({
            obstructionsGroups: (this.state.obstructionsGroups[id].layer = layer)
        });
    }


    /**
     * Remove an active obstruction
     */
    removeObstructions(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        this.map.pm.enableGlobalRemovalMode();
    }


    /**
     * Delete a specific obstruction
     * @param {string} id obstruction ID
     */
    deleteObstructions(id) {
        let obstructionsGroups = this.state.obstructionsGroups;
        delete obstructionsGroups[id];
        this.setState({
            obstructionsGroups: obstructionsGroups
        });
    }


    /**
     * Disable all obstructions
     */
    disableAllObstructions() {
        if (this.state.obstructionsGroups != {}) {
            for (const [id, obstructionsGroup] of Object.entries(this.state.obstructionsGroups)) {
                if (obstructionsGroup.active) {
                    this.state.obstructionsGroups[id].disable();
                }
            }
        }

        //this.onDrawingChanged('');
    }


    /**
     * Show loader and block the map
     */
    showLoader(callback) {
        $('body').addClass('covered');

        setTimeout(callback, 100);
    }


    /**
     * Hide loader and unblock the map
     */
    hideLoader() {
        $('body').removeClass('covered');
    }


    /**
     * Add new group of solar panels
     */
    toggleOnmapToolbar(e) {
        e.preventDefault();

        let shouldBeOpen = false;
        if (!$(e.currentTarget).parent().get(0).classList.contains('active')) {
            shouldBeOpen = true;
        }
        this.onToggleOnmapToolbar();
        $(e.currentTarget).siblings('div.toolbar-extending').find('.btn.active').removeClass('active');
        if (shouldBeOpen) {
            $(e.currentTarget).parent().toggleClass('active');
        }


        this.hideInitLocationMarker();
    }


    /**
     * Add new group of solar panels
     */
    onToggleOnmapToolbar() {
        this.collapseToolbars();
        this.disableAll();
    }


    disableAll() {
        this.disableAllGroups();
        this.disableAllSetbacks();
        this.disableAllObstructions();

        this.onDrawingChanged('');
    }


    populateSolarPanelsFromPVWatts(rawResponse) {
        if (this.state.solarPanelGroups != {}) {
            let i = 0;
            for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                this.state.solarPanelGroups[id].production = rawResponse.data.data[i].production;
                i++;
            }
        }
    }


    panelsUpdated() {
        window.appComponent.repopulateFromSolarPanels(this.state.solarPanelGroups);
    }


    calculate() {
        let panels = [],
            setbacks = {},
            obstructions = {},
            exportData = [],
            pvwattsData = [];

        if (this.state.solarPanelGroups != {}) {
            let i = 1;
            for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                panels.push(solarPanel.save());
                exportData.push(solarPanel.export());
                pvwattsData.push(solarPanel.prepareDataForPVWatts(i));
                i++;
            }
        }

        if (this.state.setbacksGroups !== {}) {
            for (const [id, setbacksGroup] of Object.entries(this.state.setbacksGroups)) {
                let setbacksExportData = setbacksGroup.export();
                if (setbacksExportData) {
                    setbacks[id] = setbacksExportData;
                }
            }
        }

        if (this.state.obstructionsGroups !== {}) {
            for (const [id, obstructionsGroup] of Object.entries(this.state.obstructionsGroups)) {
                let obstructionsExportData = obstructionsGroup.export();
                if (obstructionsExportData) {
                    obstructions[id] = obstructionsExportData;
                }
            }
        }

        return {
            panels: panels,
            setbacks: setbacks,
            obstructions: obstructions,
            exportData: exportData,
            pvwattsData: pvwattsData
        };
    }


    /**
     * Search for solar panels who can catch the click
     * @param e
     */
    bubbleClick(e) {
        let eventBubbled = false;

        let click = L.point(e.originalEvent.clientX, e.originalEvent.clientY);
        click.x -= $('#mapContainer').offset().left;
        click.y -= $('#mapContainer').offset().top;
        let latLng = this.map.containerPointToLatLng(click);

        if (this.state.solarPanelGroups != {}) {
            for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                let cellData = solarPanel.grid.getCellByLatLng(latLng);
                if (cellData != null) {
                    if ($(cellData.html).hasClass('settled')) {
                        solarPanel.enable();

                        eventBubbled = true;
                        break;
                    }
                }
            }
        }
    }


    componentDidMount() {
        this.showLoader();

        document.addEventListener("keydown", this.escFunction, false);
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }


    escFunction(e) {
        if (e.key == "Escape") {
            this.disableAll();
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(() => {
            let newProps = {};

            //check if center changed
            if (
                nextProps.center.lat != undefined &&
                nextProps.center != this.state.center
            ) {
                newProps['center'] = nextProps.center;
                //this.hideLoader();
            } else if (
                nextProps.center.lat != undefined &&
                nextProps.center != this.state.center
            ) {
                newProps['center'] = this.helper.defaults.center;
            }

            //check if map type changed
            if (
                nextProps.mapType != this.state.mapType
            ) {
                this.defaults = this.helper.setDefaultParameters(nextProps.mapType);
                newProps['mapType'] = nextProps.mapType;
            }

            //check if panel type changed
            /*if (
                nextProps.panelType != this.props.panelType
            ) {
                if (this.state.solarPanelGroups != {}) {
                    for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
                        this.state.solarPanelGroups[id].selfDelete();
                    }

                }
                window.appComponent.repopulateFromSolarPanels(this.state.solarPanelGroups);
            }*/

            return newProps;
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        //check if panel type changed
        if (
            this.props.panelType !== prevProps.panelType
        ) {
            if (prevState.solarPanelGroups !== {}) {
                for (const [id, solarPanel] of Object.entries(prevState.solarPanelGroups)) {
                    prevState.solarPanelGroups[id].selfDelete();
                }

            }
            window.appComponent.repopulateFromSolarPanels(prevState.solarPanelGroups);
        }
    }


    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.map || nextProps.mapType != this.props.mapType || Object.keys(nextState.setbacksGroups).length != Object.keys(this.state.setbacksGroups).length;
    }*/


    testLog(e) {

    }


    showRemoveAllModal(e) {
        e.preventDefault();

        this.setState({
            removeAllShown: true
        });
    }


    removeAllShown() {
        this.setState({
            removeAllProcessing: true
        });

        let solarPanelGroups = this.state.solarPanelGroups;
        for (const [id, solarPanel] of Object.entries(this.state.solarPanelGroups)) {
            solarPanelGroups[id].selfDelete();
        }
        let setbacksGroups = this.state.setbacksGroups;
        for (const [id, setbacksGroup] of Object.entries(this.state.setbacksGroups)) {
            setbacksGroups[id].selfDelete();
        }
        let obstructionsGroups = this.state.obstructionsGroups;
        for (const [id, obstructionsGroup] of Object.entries(this.state.obstructionsGroups)) {
            obstructionsGroups[id].selfDelete();
        }

        window.appComponent.onCalculate();

        this.setState({
            solarPanelGroups: [],
            setbacksGroups: [],
            obstructionsGroups: [],
            removeAllProcessing: false,
            removeAllShown: false
        });
    }


    render() {
        return (
            <div className="map-panel">
                <MapContainer
                    center={this.state.center}
                    mapType={this.state.mapType}
                    panelType={this.props.panelType}
                    route={this.props.route}
                    projectInfoReady={this.props.projectInfoReady}
                    nearmapPhoto={this.props.nearmapPhoto}
                    nearmapBBox={this.props.nearmapBBox}

                    onMapInitialized={this.onMapInitialized}
                    onCenterChanged={this.onCenterChanged}
                />
                <div id="mapContainer-project-sunroof" className="d-none"></div>

                <div className={(this.state.onmapHint?'onmap-hint':'d-none')}>
                    {parser(this.state.onmapHint)}

                    <button onClick={(e) => {e.preventDefault(); this.setOnmapHint('')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>

                <img src={imgCompass} className="onmap-compass" />

                <div className={this.props.route !== 'view' ? 'toggle-type-cover d-none' : 'toggle-type-cover d-none'}>
                    <a href="#" className="btn btn-warning toggle-type" onClick={this.toggleShadeAnalysis}>Shade Analysis</a>
                </div>

                {this.props.route === 'edit' &&
                <>
                    <div className={this.props.route !== 'edit' ? 'onmap-toolbars-cover d-none' : 'onmap-toolbars-cover'}>
                        <div className={'onmap-toolbar d-none'}>
                            <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}>
                                <img src={imgDrawObstructions}/> <span>Add Obstacles</span>
                            </a>

                            <div className="btn-block toolbar-extending">
                                <a href="#" className="btn" onClick={(e) => {
                                    this.drawObstructions(e, 'rectangle')
                                }}>Rectangle</a>
                                <a href="#" className="btn" onClick={(e) => {
                                    this.drawObstructions(e, 'circle')
                                }}>Circle</a>
                                <a href="#" className="btn" onClick={(e) => {
                                    this.drawObstructions(e, 'polygon')
                                }}>Polygon</a>
                            </div>
                        </div>

                        <div className={!window.appComponent.state.project.defaultSetbacks ? 'onmap-toolbar d-none' : 'onmap-toolbar'}>
                            <a href="#" className="btn btn-default" onClick={this.drawSetbacks}>
                                <img src={imgDrawSetbacks}/> <span>Draw Setbacks</span>
                            </a>
                        </div>

                        <div className={'onmap-toolbar'}>
                            <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}>
                                <img src={imgAddPanel}/> <span>Add new panel</span>
                            </a>

                            <div className="btn-block toolbar-extending">
                                <a href="#" className="btn" onClick={(e) => {
                                    this.createSolarPanels(e, 'portrait')
                                }}>Portrait</a>
                                <a href="#" className="btn" onClick={(e) => {
                                    this.createSolarPanels(e, 'landscape')
                                }}>Landscape</a>
                            </div>
                        </div>

                        {this.props.routeSubtype === 'request-design' && (
                        <div className={'onmap-toolbar'}>
                            <a href="#" className="btn btn-default" onClick={this.showRemoveAllModal}>
                                <img src={imgRemoveAll}/> <span>Remove All</span>
                            </a>
                        </div>
                        )}

                        <div className={'settings-container ' + ((this.state.drawingActive === 'panels') ? ('') : ('d-none'))}>
                            {this.state.solarPanelGroups && this.state.solarPanelGroups !== {} &&
                                Object.keys(this.state.solarPanelGroups).map((id) =>
                                    <SolarPanelsSettingsToolbar
                                        key={id.toString()}
                                        id={id.toString()}
                                        solarPanel={this.state.solarPanelGroups[id]}

                                        onDrawingChanged={this.onDrawingChanged}
                                    />
                                )}
                        </div>

                        {/*
                    <div className={'settings-container panel-settings '+((this.state.drawingActive === 'panels1')?(''):('d-none'))}>
                        <ul className="onmap-toolbar d-none" data-id="sample">
                            <li>
                                <a href="#" className="btn btn-default"><img src={imgSetTilt} /> <span>Set Tilt</span></a>

                                <div className="toolbar-extension toolbar-extending tilt-toolbar">
                                    <div className="toolbar-slider">
                                        <div className="toolbar-slider-progress">
                                            <div className="toolbar-slider-progress-caret"></div>
                                        </div>
                                    </div>
                                    <div className="toolbar-slider-value">45&deg;</div>
                                </div>
                            </li>
                        </ul>
                    </div>*/}

                        <div
                            className={'settings-container ' + ((window.appComponent.state.project.defaultSetbacks > 0 && this.state.drawingActive === 'setbacks') ? ('') : ('d-none'))}>
                            {this.state.setbacksGroups !== {} &&
                                Object.keys(this.state.setbacksGroups).map((id) =>
                                    <SetbackSettingsToolbar
                                        key={id.toString()}
                                        id={id.toString()}
                                        setbacks={this.state.setbacksGroups[id]}
                                        updateLayer={this.updateSetbacksLayer}
                                        onToggleOnmapToolbar={this.onToggleOnmapToolbar}
                                        changeOnmapHint={this.setOnmapHint}

                                        onDrawingChanged={this.onDrawingChanged}
                                    />
                                )}
                        </div>

                        <div className={'settings-container ' + ((this.state.drawingActive === 'obstructions') ? ('') : ('d-none'))}>
                            {this.state.obstructionsGroups !== {} &&
                                Object.keys(this.state.obstructionsGroups).map((id) =>
                                    <ObstructionSettingsToolbar
                                        key={id.toString()}
                                        id={id.toString()}
                                        obstruction={this.state.obstructionsGroups[id]}
                                        updateLayer={this.updateObstructionsLayer}
                                        onToggleOnmapToolbar={this.onToggleOnmapToolbar}
                                        changeOnmapHint={this.setOnmapHint}

                                        onDrawingChanged={this.onDrawingChanged}
                                    />
                                )}
                        </div>
                    </div>


                    <div className={'onmap-toolbars-cover lb-corner' + (this.props.route !== 'edit' ? ' d-none' : '')}>
                        <ChangeLocationToolbar
                            active={Object.keys(this.state.solarPanelGroups).length > 0}
                            toggleInitLocationMarker={this.toggleInitLocationMarker}
                        />
                    </div>

                    <div className={'onmap-toolbars-cover toggle-shadow-toolbar-container lt-corner' + (!this.state.shadeAnalysisOn ? ' d-none' : '')}>
                        <ToggleShadowsToolbar
                            toggleShadowsClicked={this.onToggleShadowsClicked}
                        />
                </div>

                <div id="color-based-shading" style={{left: this.state.colorBasedShadingX+'px', top: this.state.colorBasedShadingY+'px'}}>
                    {100-this.state.colorBasedShading-15}%
                </div>

                </>
                }
                <div id="tmpzoom" className="d-block1"></div>

                <PSAlert
                    open={this.state.removeAllShown}
                    title="Confirm remove all panels"
                    description="Please confirm if you wish to remove all panels. You cannot revert this action later."
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Confirm"
                    confirmButtonVariant="red"
                    acceptDisabled={this.state.removeAllProcessing}
                    cancelDisabled={this.state.removeAllProcessing}
                    confirmLoading={this.state.removeAllProcessing}
                    btnFixWidth={83}
                    openChanged={() => this.setState({removeAllShown: false})}
                    onAccept={this.removeAllShown}
                />
            </div>
        );
    }
}

export default Map;
