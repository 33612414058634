import React from 'react';
import {styled} from '@stitches/react';
import * as SliderPrimitive from '@radix-ui/react-slider';

const StyledSlider = styled(SliderPrimitive.Root, {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',

    '&[data-orientation="horizontal"]': {
        height: '20px',
    },

    '&[data-orientation="vertical"]': {
        flexDirection: 'column',
        width: '20px',
        height: '100px',
    },
});

const StyledTrackBase = styled(SliderPrimitive.Track, {
    backgroundColor: '#ddd',
    position: 'relative',
    flexGrow: 1,
    borderRadius: '9999px',

    '&[data-orientation="horizontal"]': {height: 4},
    '&[data-orientation="vertical"]': {width: 4},
});

const StyledRange = styled(SliderPrimitive.Range, {
    position: 'absolute',
    backgroundColor: 'rgba(39,110,250,.5)',
    borderRadius: '9999px',
    height: '100%',
});

const StyledThumb = styled(SliderPrimitive.Thumb, {
    all: 'unset',
    display: 'block !important',
    width: 10,
    height: 10,
    backgroundColor: '#276efa',
    boxShadow: `none`,
    borderRadius: 10,
    cursor: 'pointer',
    //'&:hover': {backgroundColor: violet.violet3},
    //'&:focus': {boxShadow: `0 0 0 5px ${blackA.blackA8}`},
});

const PSSlider = (props) => {

    let StyledTrack = StyledTrackBase;
    if (props.styledTrackStyles) {
        StyledTrack = styled(StyledTrackBase, props.styledTrackStyles);
    }

    return (<StyledSlider
        onValueChange={props.onChange}
        value={[props.value]}
        defaultValue={[props.defaultValue]}
        min={(props.min || props.min === 0)?(props.min):(0.5)}
        max={(props.max || props.max === 0)?(props.max):(6)}
        step={(props.step)?(props.step):(0.5)}
    >
        <StyledTrack>
            <StyledRange/>
        </StyledTrack>
        <StyledThumb style={props.styledThumbStyles}/>
    </StyledSlider>
)};

export default PSSlider;
