import * as L from "leaflet";
import SetbacksToolbar from "./SetbacksToolbar";

import './Setbacks.css';


class Setbacks {
    constructor (map, mapper, id, loadingData) {
        this.id = ((id)?(id):('ID-' + Math.round(Math.random() * 10000000)));
        this.map = map;
        this.mapper = mapper;
        this.toolbar = null;

        this.layer = null;
        this.settings = null;
        this.active = true;
        this.initComplete = false;
        this.loadedSetbacksSize = ((loadingData && loadingData.setbacksSize)?(loadingData.setbacksSize):(null));
        this.loadedDisabledSides = ((loadingData && loadingData.disabledSides)?(loadingData.disabledSides):([]));

        this.obstructions = [];

        this.drawingOptions = {
            snappable: true,
            snapDistance: 20,
            allowSelfIntersection: false,
            removeVertexOn: 'dblclick',
            tooltips: false,
            pathOptions: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
                pane: 'tilePane'
            },
            templineStyle: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
            },
            hintlineStyle: {
                color: '#ffffff',
                weight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.3,
            },
        };

        this.createLayer = this.createLayer.bind(this);
        this.activateLayer = this.activateLayer.bind(this);
        this.onboardingHints = this.onboardingHints.bind(this);
        this.vertexRemoved = this.vertexRemoved.bind(this);
        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
        this.export = this.export.bind(this);

        if (!id) {
            this.init();
        }
    }


    passiveInit(loadingData) {
        let coords = [],
            rawCoordinates = loadingData.mainLine.geometry.coordinates;

        this.initComplete = true;

        if (rawCoordinates.length) {
            for (const coordsRaw of rawCoordinates) {
                coords.push(L.latLng(coordsRaw[1], coordsRaw[0]));
            }
        }
        this.layer = L.polygon(coords).addTo(this.map);
        this.layer.pm.enable(this.drawingOptions);

        this.layer.on('click', this.activateLayer);
        this.layer.on('pm:vertexremoved', this.vertexRemoved);

        this.toolbar = new SetbacksToolbar(this.map, this);

        this.activateLayer();
        /*if (loadingData.disabledSides) {
            this.settingsComponent.disableSides(loadingData.disabledSides);
        }*/
    }


    /**
     * Entry point
     */
    init() {
        this.map.on('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', this.onboardingHints);
        });

        this.map.pm.enableDraw('Polygon', this.drawingOptions);

        this.map.on('pm:create', this.createLayer);
    }


    onboardingHints(e) {
        switch (e.workingLayer._latlngs.length) {
            case 1: this.settingsComponent.changeHint('Click to continue drawing. Click ESC to cancel current drawing.'); break;
            case 3: this.settingsComponent.changeHint('Click the first marker to finish. Click ESC to cancel current drawing.'); break;
        }
    }


    /**
     * Set current setbacks as active
     */
    enable() {
        this.mapper.disableAllSetbacks();

        this.active = true;

        this.settingsComponent.show(false);
        this.toolbar.show();

        this.map.on('click', this.disable);
    }


    /**
     * Set current setbacks as NOT active
     */
    disable() {
        if (!this.initComplete) {
            this.selfDelete();
        } else {
            this.active = false;

            if (this.settingsComponent) {this.settingsComponent.hide();}
            if (this.toolbar) {this.toolbar.hide();}

            this.map.off('click', this.disable);
            this.map.pm.disableDraw();
            this.layer.pm.disable();
            this.layer.removeFrom(this.map);
            this.settingsComponent.showSetbacks();
            this.settingsComponent.changeHint('');
        }

        this.map.off('pm:create', this.createLayer);
    }


    attachSettingsToolbar(settingsComponent) {
        this.settingsComponent = settingsComponent;
        this.settingsComponent.changeHint('Click to place the first vertex');
    }


    createLayer(e) {
        this.layer = e.layer;

        this.layer.on('click', this.activateLayer);
        this.layer.on('pm:vertexremoved', this.vertexRemoved);

        this.toolbar = new SetbacksToolbar(this.map, this);

        this.activateLayer();
    }


    activateLayer() {
        this.enableLayerEdit();

        this.settingsComponent.show();
        this.toolbar.show();
        this.map.on('click', this.disable);
        this.initComplete = true;
    }


    enableLayerEdit() {
        this.layer.addTo(this.map);

        this.layer.pm.enable({
            allowSelfIntersection: false,
        });

        this.settingsComponent.hideSetbacks();
    }


    vertexRemoved(e) {
        if (e.layer.getLatLngs()[0].length !== 0) {return;}

        this.selfDelete();
    }


    export() {
        if (!this.settingsComponent.setbacksMainLine) {return null;}

        let data = {
            mainLine: this.settingsComponent.setbacksMainLine.toGeoJSON(),
            setbacksSize: this.settingsComponent.state.setbacksSize,
            disabledSides: ((this.settingsComponent.state.disabledSides)?(this.settingsComponent.state.disabledSides):([]))
        };

        return data;
    }


    /**
     * Remove current setbacks
     */
    selfDelete() {
        this.map.pm.disableDraw();
        this.settingsComponent.changeHint('');

        this.map.off('pm:create', this.createLayer);

        if (this.layer) {this.layer.remove();}

        if (this.obstructions) {
            this.obstructions.forEach((obstruction) => {
                obstruction.remove();
            });
        }

        if (this.settingsComponent) {this.settingsComponent.hide();}
        if (this.toolbar) {this.toolbar.hide();}

        this.mapper.deleteSetbacks(this.id);
    }
}

export default Setbacks;