import * as L from "leaflet";
import * as DomUtil from "leaflet/src/dom/DomUtil";
import * as turf from "@turf/turf";
import {Point} from "leaflet/src/geometry/Point";
import * as geometric from "geometric";

import DynamicGridToolbar from "./DynamicGridToolbar";


export var DynamicGrid = L.Layer.extend({
    options: {
  		pane: 'tilePane',
  		attribution: null,
  		bubblingMouseEvents: true
  	},


    /*** ------------------ Initialization ------------------ ***/


    /**
     * Initialize the dynamic grid
     * @param panelSize
     * @param {Object} [options={}]
     */
    initialize(panelSize, options = {}) {
		L.Util.setOptions(this, options);

        this.id = L.Util.stamp(this).toString();

        this._panelSize = panelSize;

        this._toolbar = null;

        this._active = false;
        this._multipleAdding = false;
        this._originPoint = new Point(0, 0);
        this._containerTranslate = new Point(0, 0);
	},

	_initContainer() {
		if (this._container) { return; }

        this._panelSizePx = [
            this._m2px(this._panelSize[0], this._map.getZoom()),
            this._m2px(this._panelSize[1], this._map.getZoom())
        ];

        this._tiles = {};
        //this._setAzimuth(0);
        this._setAzimuth(180);
        this._latlngs = [];

        this._layerContainer = DomUtil.create('div', 'leaflet-layer dynamic-grid-container');
		this._container = DomUtil.create('div', `dynamic-grid ${this.options.className || ''}`, this._layerContainer);

		this.getPane().appendChild(this._layerContainer);

        this._createOriginPoint();
        this._createAzimuthArrow();
	},

    activate() {
        this._layerContainer.classList.add('active');
        this._active = true;

        this._toolbar.update();
    },

    deactivate() {
        this._layerContainer.classList.remove('active');
        this._active = false;
    },

    selfDelete() {
        this._layerContainer.remove();
    },


    /*** ------------------ Tiles ------------------ ***/


    /**
     * Place a tile at the given latlng
     * @param {LatLng} latlng
     * @param {boolean} isBatchOperation
     */
    placeTile: function(latlng, isBatchOperation = false) {
        let tileDom = DomUtil.create('div', `dynamic-tile potential`);
        this._addTileEvents(tileDom);
        let tileID = L.Util.stamp(tileDom);

        tileDom.style.width = `${this._panelSizePx[0]}px`;
        tileDom.style.height = `${this._panelSizePx[1]}px`;

        this._tiles[tileID] = {
            dom: tileDom,
            latlng: latlng,
            settled: false
        };

        this._latlngs.push(latlng);

        const layerPoint = this.latLngToPoint(latlng);
        this._setTilePosition(this._tiles[tileID], layerPoint);

		this._container.appendChild(this._tiles[tileID].dom);

        if (!isBatchOperation) {
            this._updateLatLngBounds();
        }

        return tileID;
    },

    _settleTile(tileID) {
        const tile = this._tiles[tileID];
        tile.dom.classList.remove('potential');
        tile.dom.classList.add('settled');
        tile.settled = true;

        let neighborLatLngs = this._getNeighborLatLngs(tile.latlng);
        for (const latlng of neighborLatLngs) {
            if (!this._latLngsContain(latlng)) {
                this.placeTile(latlng);
            }
        }
    },

    _unsettleTile(tileID) {
        const tile = this._tiles[tileID];
        tile.dom.classList.remove('settled');
        tile.dom.classList.add('potential');
        tile.settled = false;

        let neighborLatLngs = this._getNeighborLatLngs(tile.latlng),
            shouldUpdateGrid = false,
            shouldBeSelfRemoved = true;
        for (let latlng of neighborLatLngs) {
            let neighborTileID = this._getTileIDByLatLng(latlng);
            if (neighborTileID && !this._tiles[neighborTileID].settled) {
                let tileNeighborLatLngs = this._getNeighborLatLngs(latlng),
                    shouldBeRemoved = true;

                for (let neighborLatLng of tileNeighborLatLngs) {
                    let subneighborTileID = this._getTileIDByLatLng(neighborLatLng);

                    if (subneighborTileID && this._tiles[subneighborTileID].settled) {
                        shouldBeRemoved = false;
                    }
                }

                if (shouldBeRemoved) {
                    this._removeTile(neighborTileID);
                    shouldUpdateGrid = true;
                }
            } else if (neighborTileID && this._tiles[neighborTileID].settled) {
                shouldBeSelfRemoved = false;
            }
        }

        if (shouldBeSelfRemoved) {
            this._removeTile(tileID);
            shouldUpdateGrid = true;
        }

        if (shouldUpdateGrid && this._latlngs.length > 0) {
            this._updateLatLngBounds();
        }
    },

    _removeTile(tileID) {
        let latlng = this._tiles[tileID].latlng;

        this._removeTileEvents(this._tiles[tileID].dom);
        this._tiles[tileID].dom.remove();
        delete this._tiles[tileID];
        this._latlngs.splice(this._latlngs.indexOf(latlng), 1);
    },

    _addTileEvents(tileDom) {
        tileDom.addEventListener('click', this._onTileClick.bind(this));

        tileDom.addEventListener('mousedown', this._onTileMouseDown.bind(this), false);
        tileDom.addEventListener('touchstart', this._onTileMouseDown.bind(this), false);

        tileDom.addEventListener('mousemove', this._onTileMouseOver.bind(this), false);
        tileDom.addEventListener('touchmove', this._onTileMouseOver.bind(this), false);

        tileDom.addEventListener('mouseup', this._onTileMouseUp.bind(this), false);
        tileDom.addEventListener('touchend', this._onTileMouseUp.bind(this), false);
        tileDom.addEventListener('touchcancel', this._onTileMouseUp.bind(this), false);
    },

    _removeTileEvents(tileDom) {
        tileDom.removeEventListener('click', this._onTileClick.bind(this));

        tileDom.removeEventListener('mousedown', this._onTileMouseDown.bind(this), false);
        tileDom.removeEventListener('touchstart', this._onTileMouseDown.bind(this), false);

        tileDom.removeEventListener('mousemove', this._onTileMouseOver.bind(this), false);
        tileDom.removeEventListener('touchmove', this._onTileMouseOver.bind(this), false);

        tileDom.removeEventListener('mouseup', this._onTileMouseUp.bind(this), false);
        tileDom.removeEventListener('touchend', this._onTileMouseUp.bind(this), false);
        tileDom.removeEventListener('touchcancel', this._onTileMouseUp.bind(this), false);
    },

    /**
     * Returns true if the given latlng is already in the grid
     * @param {LatLng} latlng
     * @returns {boolean}
     * @private
     */
    _latLngsContain(latlng) {
        for (const latlng2 of this._latlngs) {
            if (latlng2.distanceTo(latlng) < 0.2) {
                return true;
            }
        }
        return false;
    },

    /**
     * Get TileID by latlng
     * @param {LatLng} latlng
     * @returns {null|string}
     * @private
     */
    _getTileIDByLatLng(latlng) {
        for (const tileID in this._tiles) {
            const tile = this._tiles[tileID];
            if (tile.latlng.distanceTo(latlng) < 0.2) {
                return tileID;
            }
        }
        return null;
    },

    /**
     * Returns an array of latlngs of the 4 neighbors of the given latlng
     * @param latlng
     * @returns {LatLng[]}
     * @private
     */
    _getNeighborLatLngs(latlng) {
        const azimuth = this.getAzimuth();

        const right = this._destination(latlng, this._panelSize[0], azimuth - 90),
            left = this._destination(latlng, this._panelSize[0], azimuth + 90),
            top = this._destination(latlng, this._panelSize[1], azimuth+180),
            bottom = this._destination(latlng, this._panelSize[1], azimuth);

        return [top, right, bottom, left];
    },

    _updateLatLngBounds() {
        this._latLngBounds = new L.latLngBounds(this._latlngs);
        this._latLngCenter = this._findCenter();

        //get radius
        this._latLngRadius = 0;
        for (const latlng of this._latlngs) {
            const dist = latlng.distanceTo(this._latLngCenter);
            if (dist > this._latLngRadius) {
                this._latLngRadius = dist;
            }
        }

        this._updateOrigin();
    },

    _setTilePosition(tile, pos) {
        tile.dom.style.left = pos.x + 'px';
        tile.dom.style.top = pos.y + 'px';

        tile.pos = pos;
    },

    /**
     * Similar to Leaflet's original latLngToLayerPoint, but without rounding
     * @param {LatLng} latlng
     * @param {decimal} [zoom]
     * @returns {Point}
     */
    latLngToPoint: function (latlng, zoom) {
		let point = this._latLngToMapContainerPoint(latlng, zoom);

        point = point.subtract(this._map._getMapPanePos());
        point = point.subtract(this._containerTranslate);
        point = this.rotatePoint(this._originPoint, (-1)*this.getAzimuth(), point);

  		return point;
  	},

    /**
     * Convert coords to map container point without rounding
     * @param {LatLng} latlng
     * @param {decimal} [zoom]
     * @returns {Point}
     * @private
     */
    _latLngToMapContainerPoint: function (latlng, zoom) {
		const projectedPoint = this._map.project(latlng, zoom);
		const point = projectedPoint._subtract(this._map.getPixelOrigin());

		return this._map.layerPointToContainerPoint(point);
    },


    /**
     * Convert grid Point to map container point
     * @param {Point} gridPoint
     * @returns {Point}
     * @private
     */
    _gridPointToMapContainerPoint: function (gridPoint) {
        let realTranslate = this._getRealTranslate(),
            mapPoint = this.rotatePoint(L.point(0, 0), this.getAzimuth(), gridPoint);

        mapPoint = mapPoint.add(realTranslate);

        return mapPoint;
    },

    _updateTilesDomPositions() {
        for (const tile of Object.values(this._tiles)) {
            const layerPoint = this.latLngToPoint(tile.latlng);
            this._setTilePosition(tile, layerPoint);
        }
    },

    _updateTilesLatLngPositions() {
        let updatedLatLngs = [];
        let i = 0;
        for (const [key, tile] of Object.entries(this._tiles)) {
            const middlePoint = this._getTileMiddlePoint(tile.dom),
                middlePointOnMap = this._gridPointToMapContainerPoint(middlePoint),
                correctedMiddlePoint = middlePointOnMap.add(this._map._getMapPanePos());

            this._tiles[key].latlng = this._map.containerPointToLatLng(correctedMiddlePoint);

            updatedLatLngs.push(this._tiles[key].latlng);
        }

        this._latlngs = updatedLatLngs;
        this._updateLatLngBounds();
    },

    /**
     * Returns the middle point of the given tile
     * @param {HTMLElement} tileDom
     * @returns {Point}
     * @private
     */
    _getTileMiddlePoint(tileDom) {
        const posLeft = parseFloat(tileDom.style.left),
            posTop = parseFloat(tileDom.style.top);

        return new Point(posLeft, posTop);
    },

    /**
     * Get real current grid transform
     */
    _getRealTranslate() {
        let translate = this._containerTranslate.clone(),
            angle = this.getAzimuth();

        let newTranslate = this.rotatePoint(this._originPoint, angle, L.point(0,0));

        translate.x += newTranslate.x;
        translate.y += newTranslate.y;

        return translate;
    },

    /**
     * Rotate point around origin
     * @param {Point} origin
     * @param {number} angleDeg
     * @param {Point} target
     * @returns {Point}
     */
    rotatePoint(origin, angleDeg, target) {
        let newP = geometric.pointRotate([target.x, target.y], angleDeg, [origin.x, origin.y]);

        return new Point(newP[0], newP[1]);
    },

    _updateTilesPxSizes(mapCenter, mapZoom) {
        if (!mapCenter) {mapCenter = this._map.getCenter();}
        if (!mapZoom) {mapZoom = this._map.getZoom();}

        this._panelSizePx[0] = this._m2px(this._panelSize[0], mapZoom);
        this._panelSizePx[1] = this._m2px(this._panelSize[1], mapZoom);

        for (const tile of Object.values(this._tiles)) {
		    const layerPoint = this._map._latLngToNewLayerPoint(tile.latlng, mapZoom, mapCenter);
            this._setTilePosition(tile, layerPoint);

            tile.dom.style.width = this._panelSizePx[0]+ 'px';
            tile.dom.style.height = this._panelSizePx[1]+ 'px';
        }
    },

    /**
     * Returns the number of settled tiles
     * @returns {number}
     */
    getSettledTilesCount() {
        let total = 0;
        for (const tile of Object.values(this._tiles)) {
            if (tile.settled) {
                total++;
            }
        }

        return total;
    },

    /**
     * Returns coords of settled tiles
     * @returns {LatLngs[]}
     */
    getSettledTilesLatLngs() {
        let settled = [];
        for (const tile of Object.values(this._tiles)) {
            if (tile.settled) {
                settled.push(tile.latlng);
            }
        }

        return settled;
    },



    /*** ------------------ Azimuth ------------------ ***/

    /**
     * Returns current azimuth in degrees in a range [-180, 180], where 0 is South, 90 is West, 180/-180 is North, -90 is East
     * @returns {number|*|number}
     */
    getAzimuth() {
        return this._azimuth;
    },

    /**
     * Sets azimuth in degrees in a range [-180, 180]
     * @param azimuth angle in degrees, where 0 is South, 90 is West, 180/-180 is North, -90 is East
     * @private
     */
    _setAzimuth(azimuth) {
        if (azimuth > 180) {
            azimuth = (azimuth % 360) - 360;
        } else if (azimuth < -180) {
            azimuth = (azimuth % 360) + 360;
        }

        this._azimuth = parseFloat(azimuth);
    },

    /**
     * Create a DOM element for azimuth arrow
     */
    _createAzimuthArrow() {
        this._azimuthArrowDom = DomUtil.create('div', `dynamic-grid-azimuth-arrow`);
		this._container.appendChild(this._azimuthArrowDom);
        this._azimuthArrowDom.style.left = `${this._originPoint.x}px`;
        this._azimuthArrowDom.style.top = `${this._originPoint.y}px`;
    },

    _updateAzimuthArrow() {
        this._azimuthArrowDom.style.left = `${this._originPoint.x}px`;
        this._azimuthArrowDom.style.top = `${this._originPoint.y}px`;
    },



    /*** ------------------ Origin Point ------------------ ***/


    _createOriginPoint() {
		this._originPointDom = DomUtil.create('div', `dynamic-grid-origin`);
		this._container.appendChild(this._originPointDom);
    },
    _updateOrigin() {
        const originPoint = this.latLngToPoint(this._latLngCenter);

        const firstTile = this._tiles[Object.keys(this._tiles)[0]];

        const oldMapPoint = this._gridPointToMapContainerPoint(firstTile.pos);

        this._container.style.transformOrigin = `${originPoint.x}px ${originPoint.y}px`;

        this._originPointDom.style.left = `${originPoint.x}px`;
        this._originPointDom.style.top = `${originPoint.y}px`;

        this._originPoint = new Point(originPoint.x, originPoint.y);
        this._originPointLatLng = this._latLngCenter;

        const newMapPoint = this._gridPointToMapContainerPoint(firstTile.pos);
        const diff = newMapPoint.subtract(oldMapPoint);

        this._containerTranslate = this._containerTranslate.subtract(diff);
        this._updateContainerTranslate();

        this._updateAzimuthArrow();
        this._toolbar.update();
    },

    rotate(angle, isBatch = false) {
        this._setAzimuth(angle);

        this._updateContainerTranslate();

        if (!isBatch) {
            this._updateTilesLatLngPositions();
        }
    },

    _updateContainerTranslate() {
        this._container.style.transform = `translate(${this._containerTranslate.x}px, ${this._containerTranslate.y}px) rotate(${this.getAzimuth()}deg)`;
    },

	update() {
		if (this._container && this._map) {
            this._updateTilesDomPositions();
		}

		return this;
	},

    /**
     * Find center of masses for LatLngs
     * @return {LatLng}
     */
    _findCenter() {
        let center = null;

        if (this._latlngs.length > 2) {
            let data = [];
            this._latlngs.forEach(function(latLng) {
                data.push(turf.point([latLng.lng, latLng.lat]));
            });

            let points = turf.featureCollection(data);
            let hull = turf.convex(points);
            if (hull != null) {
                let centerOfMass = turf.centerOfMass(hull);
                let centerRaw = turf.getCoord(centerOfMass);

                center = L.latLng(centerRaw[1], centerRaw[0]);
            } else {
                center = L.latLngBounds(this._latlngs).getCenter();
            }
        } else if (this._latlngs.length > 1) {
            center = L.latLngBounds(this._latlngs).getCenter();
        } else if (this._latlngs.length > 0) {
            center = this._latlngs[0];
        }

        return center;
    },



    /*** ------------------ Event Handlers ------------------ ***/


    getEvents() {
        return {
            zoomend: this._onZoomEnd,
            //zoom: this.update,
			viewreset: this.update
        }
    },

    beforeAdd: function(map) {
    },

    onAdd: function(map) {
        this._map = map;

        this._zoomAnimated = this._zoomAnimated && map.options.markerZoomAnimation;

		if (this._zoomAnimated) {
			map.on('zoomanim', this._onAnimateZoom, this);
			map.on('zoomend', this._onZoomEnd, this);
		}

		this._initContainer();

        this._toolbar = new DynamicGridToolbar(this, map);
    },

    onRemove: function(map) {
        this.getPane().getContainer().removeChild(this._container);
    },

    _onAnimateZoom(opt) {
	},

    _onZoomEnd(e) {
        this._updateTilesPxSizes();

        this._updateOrigin();
        this.update();
	},

    /**
     * Process click on a tile
     * @param {MouseEvent} e
     * @private
     */
    _onTileClick(e) {
        e.preventDefault();
        e.stopPropagation();

        this._multipleAdding = false;

        return false;
    },

    /**
     * Process mousedown event on a tile
     * @param {MouseEvent} e
     * @private
     */
    _onTileMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this._mousedown = true;
        if (e.type.indexOf('touch') === -1 && e.button !== 0) {return;}

        if (this._active) {
            this._savePositionBeforeDrag(e);
        }

        const clientXY = this._getEventPoint(e);
        const tile = this._getTileByPoint(clientXY);
        const tileSettled = tile && tile.settled;

        if (this._active && !tileSettled) {
            this._onActiveTileClick(e);
        }

        return false;
    },

    _savePositionBeforeDrag(e) {
        const clientXY = this._getEventPoint(e);

        this._isDragging = new Date().getTime();
        this._dragging = {
            init: {
                gridPosition: new Point(this._containerTranslate.x, this._containerTranslate.y),
                toolbarPosition: new Point(this._toolbar._containerPosition.x, this._toolbar._containerPosition.y),
                clientPosition: new Point(clientXY.x, clientXY.y),
            },
            moved: false
        };
    },

    _onTileMouseDrag(e) {
        const draggingTolerance = 100; // ms
        if (this._isDragging && ((new Date().getTime() - this._isDragging) > draggingTolerance)) {
            if (!this._dragging.moved) {
                this._dragging.moved = true;
            }

            this._onGridDrag(e);
        }
    },

    _onGridDrag(e) {
        const clientXY = this._getEventPoint(e);

        const diffX = this._dragging.init.clientPosition.x - clientXY.x;
        const diffY = this._dragging.init.clientPosition.y - clientXY.y;

        //update grid position
        this._containerTranslate = new Point(this._dragging.init.gridPosition.x - diffX, this._dragging.init.gridPosition.y - diffY);
        this._updateContainerTranslate();

        //update toolbar position
        let newContainerPosition = this._dragging.init.toolbarPosition.subtract(new Point(diffX, diffY));
        this._toolbar._container.style.left = newContainerPosition.x + 'px';
        this._toolbar._container.style.top = newContainerPosition.y + 'px';
    },


    /**
     * Process mouseup event on a tile
     * @param {MouseEvent} e
     * @private
     */
    _onTileMouseUp(e) {
        e.preventDefault();
        e.stopPropagation();
        this._mousedown = false;

        if (!this._multipleAdding && this._dragging && !this._dragging.moved && this._active) {
            this._onActiveTileClick(e, null, false);
        } else if (!this._active) {
            this._onPassiveTileClick(e);
        }

        if (!this._multipleAdding) {
            this._onGridDragEnd(e);
        }
        this._multipleAdding = false;
        this._multipleAddingType = null;

        return false;
    },

    _onGridDragEnd(e) {
        if (!this._isDragging || !this._dragging.moved) {return;}

        this._isDragging = false;
        this._dragging = null;
        this._updateTilesLatLngPositions();
    },

    /**
     * Process mouseover event on a tile
     * @param {MouseEvent} e
     * @private
     */
    _onTileMouseOver(e) {
        if (!this._active || !this._mousedown) {return;}

        e.preventDefault();
        e.stopPropagation();

        if (this._multipleAdding) {
            this._onActiveTileClick(e, true);
        } else {
            this._onTileMouseDrag(e);
        }

        return false;
    },

    _getEventPoint(e) {
        let clientX, clientY;
        if (e.type.includes('touch')) {
            if (e.touches.length) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else if (e.changedTouches.length) {
                clientX = e.changedTouches[0].clientX;
                clientY = e.changedTouches[0].clientY;
            } else {
                return false;
            }
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }

        return new Point(clientX, clientY);
    },

    _getTileByPoint(point) {
        const element = document.elementFromPoint(point.x, point.y),
            tileID = L.Util.stamp(element);
        return this._tiles[tileID];
    },

    /**
     * Process click on a tile on activated grid
     * @param {MouseEvent} e
     * @param {boolean} [onlyPotentials]
     * @param {boolean} [cancelOnSettled]
     * @private
     */
    _onActiveTileClick(e, onlyPotentials, cancelOnSettled = false) {

        let clientXY = this._getEventPoint(e);

        const element = document.elementFromPoint(clientXY.x, clientXY.y),
            tileID = L.Util.stamp(element),
            tile = this._tiles[tileID];

        if (!tile) {return;}

        if (cancelOnSettled && tile.settled) {return;}

        if (tile.settled && (!onlyPotentials || this._multipleAddingType === 'unsettling')) {
            if (this.getSettledTilesCount() === 1) {return;} // at least one tile should be settled

            if (!this._multipleAddingType) {
                this._multipleAddingType = 'unsettling';
            }
            //this._multipleAdding = true; // disable multiple adding for now

            this._unsettleTile(tileID);
        } else if (!tile.settled && this._multipleAddingType !== 'unsettling') {
            if (!this._multipleAddingType) {
                this._multipleAddingType = 'settling';
            }
            this._multipleAdding = true; // disable multiple adding for now

            this._settleTile(tileID);
        }
    },

    _onPassiveTileClick(e) {
        //console.log('_onPassiveTileClick');
        this.activate();
    },



    /*** ------------------ Calculations and Helper methods ------------------ ***/


    _m2px(distance, zoom) {
        zoom = zoom || this._map.getZoom();

        const l2 = this._destination(this._map.getCenter(), distance, 90),
            p1 = this.latLngToPoint(this._map.getCenter(), zoom),
            p2 = this.latLngToPoint(l2, zoom);

        return p1.distanceTo(p2)
    },

    /**
     * Get Destination point from a given point at a given distance and angle
     * @param {L.LatLng} curLatLng  base location
     * @param {number} distance distance in meters
     * @param {number} bearing angle in degrees, where 0 is South, 90 is West, 180/-180 is North, -90 is East
     * @returns {L.LatLng} destination point
     * @private
     */
    _destination(curLatLng, distance, bearing) {
        const turfPoint = turf.point([curLatLng.lng, curLatLng.lat]),
            turnDistance = distance / 1000, //convert to km
            turfBearing = ((bearing - 180) + 180) % 360 - 180, //put angle to North-oriented, in range -180 to 180
            options = {units: 'kilometers'},

            turfDestination = turf.destination(turfPoint, turnDistance, turfBearing, options);

        return L.latLng(turfDestination.geometry.coordinates[1], turfDestination.geometry.coordinates[0]);
    },



    /*** ------------------ Secondary ------------------ ***/


    addDomPointToGrid(point) {
		let dom = DomUtil.create('div', `yellow-circle`);
		this._container.appendChild(dom);
        dom.style.left = `${point.x}px`;
        dom.style.top = `${point.y}px`;
    },
    hideAllDomPointsOnGrid() {
        document.querySelectorAll('.yellow-circle').forEach((item) => {item.remove();});
    },
    addDomPointToMap(point) {
		let dom = DomUtil.create('div', `blue-circle`);
		this._map.getContainer().querySelector('.leaflet-map-pane').appendChild(dom);
        dom.style.left = `${point.x}px`;
        dom.style.top = `${point.y}px`;
    },
    hideAllDomPointsOnMap() {
        document.querySelectorAll('.blue-circle').forEach((item) => {item.remove();});
    },

    getAttribution() {return this.options.attribution;},
});