import React, { Component } from 'react';

import './BottomPanel.css';
import Loading from "../General/Loading";
import BottomPanelInfo from "./BottomPanelInfo";
import PSAlert from "../General/PSAlert";

class BottomPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notReadyToStart: true,
            hasStarted: false,
            isComplete: false,
            status: null,
            dateStarted: null,
            dateEnded: null,
            seconds: 0,
            minutes: 0,
            hours: 0,
            revisions: 1,
            data: null,
        }

        this.statuses = {
            NOT_STARTED: '10-not-started',
            READY_TO_START: '20-ready-to-start',
            WORKING: '30-working',
            COMPLETED: '40-completed',
            UNFULFILLABLE: '50-unfulfillable',
            alertOpen: false,
        };

        this.timer = null;

        this.calculateTimeSinceStart = this.calculateTimeSinceStart.bind(this);
        this.init = this.init.bind(this);
        this.onStartClick = this.onStartClick.bind(this);
        this.onCompleteClick = this.onCompleteClick.bind(this);
        this.onReviseClick = this.onReviseClick.bind(this);
        this.onUnfulfillableClick = this.onUnfulfillableClick.bind(this);
    }


    strToDate(str) {
        return new Date(str.replace(' ', 'T') + 'Z');
    }


    onReviseClick(e) {
        e.preventDefault();

        if (!this.state.isComplete) {return;}

        window.appComponent.apiHelper.reviseDesignRequest(this.props.uid).then((info) => {
            if (info.success) {
                this.setState({
                    notReadyToStart: false,
                    hasStarted: true,
                    isComplete: false,
                    dateStarted: this.strToDate(info.dateStarted),
                    dateEnded: null,
                    totalTracked: info.totalTracked,
                    /*hours: 0,
                    minutes: 0,
                    seconds: 0,*/
                    status: info.status,
                    revisions: info.revisions
                });
            }
        });
    }


    async onCompleteClick(e) {
        e.preventDefault();

        if (this.state.notReadyToStart || !this.state.hasStarted || this.state.isComplete) {return;}

        window.appComponent.apiHelper.completeDesignRequest(this.props.uid).then((info) => {
            if (info.success) {
                this.setState({
                    isComplete: true,
                    dateStarted: null,
                    dateEnded: null,
                    totalTracked: info.totalTracked,
                    status: info.status,
                }, () => {
                    window.appComponent.onCalculate(null, () => {
                        window.appComponent.notifier.clear();
                        setTimeout(() => {
                            window.appComponent.notifier.successCentered('Successfully Completed!');
                        }, 1000);
                    }, true);
                });
            }
        });
    }


    onStartClick(e) {
        e.preventDefault();

        if (this.state.notReadyToStart || this.state.hasStarted || this.state.isComplete) {return;}

        window.appComponent.apiHelper.startDesignRequest(this.props.uid).then((info) => {
            if (info.success) {
                this.setState({
                    hasStarted: true,
                    status: info.status,
                    dateStarted: this.strToDate(info.dateStarted),
                    dateEnded: null,
                });
            }
        });
    }


    onUnfulfillableClick(e) {
        e.preventDefault();

        if (this.state.status === this.statuses.UNFULFILLABLE) {return;}

        this.setState({alertOpen: true});
    }


    init() {
        window.appComponent.apiHelper.getDesignRequestsInfo(this.props.uid).then((info) => {
            let states = {
                status: info.status,
                revisions: info.revisions,
                totalTracked: info.totalTracked,
                data: info.data
            };

            if (info.status === this.statuses.NOT_STARTED) {
                states.notReadyToStart = true;
            } else if (info.status === this.statuses.READY_TO_START) {
                states.notReadyToStart = false;
            } else if (info.status === this.statuses.WORKING) {
                states.notReadyToStart = false;
                states.hasStarted = true;
                states.dateStarted = this.strToDate(info.dateStarted);
            } else if (info.status === this.statuses.COMPLETED || info.status === this.statuses.UNFULFILLABLE) {
                states.notReadyToStart = false;
                states.hasStarted = true;
                states.isComplete = true;
                states.dateStarted = null;
                states.dateEnded = null;
            }
            this.setState(states);
        });
    }


    componentDidMount() {
        this.timer = setInterval(() => this.calculateTimeSinceStart(), 1000);

        this.init();
    }


    calculateTimeSinceStart() {
        let trackedSecondsTotal = this.state.totalTracked;

        if (this.state.dateStarted !== null) {
            let now = Date.parse((new Date()).toUTCString());
            if (this.state.dateEnded !== null) {
                now = this.state.dateEnded;
            }
            const diff = now - this.state.dateStarted;

            trackedSecondsTotal += Math.floor(diff / 1000);
        }

        let trackedHours = Math.floor(trackedSecondsTotal / 3600);
        let trackedMinutes = Math.floor((trackedSecondsTotal - trackedHours * 3600) / 60);
        let trackedSeconds = trackedSecondsTotal % 60;

        this.setState({
            seconds: trackedSeconds,
            minutes: trackedMinutes,
            hours: trackedHours,
        });
    }


    onAlertOpenChanged = (open) => {
        this.setState({alertOpen: open});
    }


    onAlertAccept = () => {
        if (this.state.isCalculating) {return;}

        window.appComponent.apiHelper.unfulfillDesignRequest(this.props.uid).then((info) => {
            if (info.success) {
                this.setState({
                    dateStarted: null,
                    dateEnded: null,
                    notReadyToStart: false,
                    hasStarted: true,
                    isComplete: true,
                    status: info.status,
                }, () => {
                    window.appComponent.notifier.successCentered('Successfully Set as Unfulfillable!');
                });
            }
        }).finally(() => {
            this.setState({alertOpen: false});
        });
    }


    render() {
        if (this.props.routeSubtype !== 'request-design') {
            return null;
        }

        return (
            <>
                <div className={'bottom-panel-cover '+((this.state.status !== this.statuses.WORKING) ? 'active' : '')}>

                </div>

                <PSAlert
                    open={this.state.alertOpen}
                    title="Confirm Status Change to 'Unfulfillable'"
                    description="Are you sure you want to mark this design request as 'Unfulfillable'? This action indicates that the request cannot be processed due to specific site conditions. Please confirm to proceed."
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Confirm"
                    openChanged={this.onAlertOpenChanged}
                    onAccept={this.onAlertAccept}
                />

                <div className="bottom-panel">
                    {this.state.status !== this.statuses.WORKING && (
                        <div className="bottom-panel-co1ver"></div>
                    )}
                    {!this.state.notReadyToStart && this.state.hasStarted && (
                        <div className="timer">
                            <BottomPanelInfo data={this.state.data} tac={this.props.tac}/>
                            <div className="labels">
                                <div className="label">Min</div>
                                <div className="label-divider"></div>
                                <div className="label">Sec</div>
                            </div>
                            <div className="values">
                                <div className="value">{String(this.state.minutes).padStart(2, '0')}</div>
                                <div className="value-divider">:</div>
                                <div className="value">{String(this.state.seconds).padStart(2, '0')}</div>
                            </div>
                        </div>
                    )}

                    {(this.state.isComplete || !this.state.isComplete && this.state.revisions > 1) && (
                        <div className="form-group">
                            <button className="btn btn-outline-success active" disabled={!this.state.isComplete} onClick={this.onReviseClick}>Revise Task
                            </button>
                        </div>)}

                    {!this.state.isComplete && this.state.revisions == 1 && (
                        <div className="form-group">
                            <button className="btn btn-outline-success active"
                                    disabled={this.state.notReadyToStart || this.state.hasStarted || this.state.isComplete} onClick={this.onStartClick}>Start
                                Task
                            </button>
                        </div>)}

                    <div className="form-group text-end">
                        <button className="btn btn-outline-success active"
                                disabled={this.state.notReadyToStart || !this.state.hasStarted || this.state.isComplete} onClick={this.onCompleteClick}>
                            <Loading visible={this.state.isCalculating} label="Mark as Complete"/>
                        </button>
                    </div>

                    {/*<div className="form-group text-end">
                        <button className="btn btn-outline-success active"
                                disabled={this.state.status === this.statuses.UNFULFILLABLE} onClick={this.onUnfulfillableClick}>
                            <Loading visible={this.state.isCalculating} label="Set as Unfulfillable"/>
                        </button>
                    </div>*/}
                </div>
            </>
        );
    }
}

export default BottomPanel;
