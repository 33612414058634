import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {SelectStyles} from '../General/SelectStyles';

export default function TPPanelTypeField(props) {
    const [panelTypesFormatted, setPanelTypesFormatted] = useState([]);


    useEffect(() => {
        let panelTypesTmp = [];

        if (props.panelTypes) {
            for (let panelType of Object.values(props.panelTypes)) {
                panelTypesTmp.push({
                    value: panelType.id,
                    label: panelType.displayed_text
                });
            }
        }

        setPanelTypesFormatted(panelTypesTmp);
    }, [props.panelTypes]);


    const panelTypeChanged = (selectedOption) => {
        if (selectedOption.value !== props.value && (props.changeAvailable || !props.changeAvailable && window.confirm('Are you sure you want to cancel current design?'))) {
            props.onChange(selectedOption.value);
        }
    }


    const getSelectedPanelType = () => {
        if (props.value && props.panelTypes) {
            const selectedType = Object.values(props.panelTypes).find(panelType => panelType.id === props.value);

            if (selectedType) {
                return {
                    value: selectedType.id,
                    label: selectedType.displayed_text
                };
            } else {
                return null;
            }
        }

        return null;
    }


    return (
        <div className="form-group">
            <Select
                name="panel-type"
                classNamePrefix="ps-"
                value={getSelectedPanelType()}
                placeholder="Panel Type"
                options={panelTypesFormatted}
                onChange={panelTypeChanged}
                isSearchable={false}
                isMulti={false}
                styles={SelectStyles}
            />
        </div>
    );
}
