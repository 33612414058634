import React, { Component } from 'react';
import $ from "jquery";
/* global google */

class TPAddressField extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.addressChanged = this.addressChanged.bind(this);
    }


    geocodeAddress() {
        let that = this,
            geocoder = new google.maps.Geocoder();

        geocoder.geocode({address: this.props.address}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                that.props.onChange({
                    address: ((that.props.address)?(that.props.address.replace(', USA', '')):(that.props.address)),
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
            }
        });
    }



    addressChanged() {
        let place = this.autocomplete.getPlace(),
            newAddress = place.formatted_address;

        this.props.onChange({
            address: ((newAddress)?(newAddress.replace(', USA', '')):(newAddress)),
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
    }


    componentDidMount() {
        if (window.appComponent.state.gmAPILoaded) {
            this.initAutocomplete();
        } else {
            window.addEventListener('google-maps-loaded', () => this.initAutocomplete());
        }
    }

    /*componentWillReceiveProps(props) {
        let address = 'Using Coordinates Mode';
        if (props.address && props.address.length) {
            address = props.address.replace(', USA', '');

            this.setState({
                address: address,
                lat: props.lat,
                lng: props.lng,
            });
        }
    }*/


    initAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current);

        this.autocomplete.addListener('place_changed', this.addressChanged);

        if (this.props.address != null && this.props.address.length && this.props.lat == false && this.props.lng == false) {
            this.geocodeAddress();
        }
    }


    render() {
        return (
            <div className="form-group" id="map-address-container">
                <input ref={this.autocompleteInput} type="text" value={this.props.address} disabled="disabled" className="form-control" name="address" id="map-address"/>
            </div>
        );
    }
}

export default TPAddressField;