import React, { Component } from 'react';

//import iconChangeLocation from './../../assets/img/mobility.svg';
import iconChangeLocation from './../../assets/img/initial-location-pin.png';

import $ from "jquery";
import * as L from "leaflet";


class ChangeLocationToolbar extends Component {
    constructor(props) {
        super(props);

        this.onMarkerToggle = this.onMarkerToggle.bind(this);
    }


    onMarkerToggle(e) {
        e.preventDefault();

        this.props.toggleInitLocationMarker();
    }


    render() {
        return (
            <div className={'onmap-toolbar'+((!this.props.active)?(' d-none'):(''))}>
                <a href="#" className="btn btn-default btn-icon" onClick={this.onMarkerToggle}>
                    <img src={iconChangeLocation} />
                </a>
            </div>
        );
    }
}

export default ChangeLocationToolbar;
