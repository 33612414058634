import React, { Component } from 'react';
import toastr from "toastr";

import "toastr/build/toastr.min.css";

class Notifier {
    constructor() {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-left",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        this.defaults = {
            title: 'Please wait',
            description: 'System Calculating...'
        };
    }


    success(title, description, customOptions) {
        if (title == null) {title = this.defaults.title;}
        if (description == null) {description = this.defaults.description;}
        if (customOptions == null) {customOptions = {};}

        toastr["success"](description, title, customOptions);
    }


    successCentered(description) {
        if (description == null) {description = this.defaults.description;}

        toastr["success"](description, this.defaults.title, {
            "positionClass": "toast-top-center",
            "timeOut": "5000",
        });
    }


    warning(title, description) {
        if (title == null) {title = this.defaults.title;}
        if (description == null) {description = this.defaults.description;}

        toastr["warning"](description, title);
    }


    warningCentered(title, description) {
        if (title == null) {title = this.defaults.title;}
        if (description == null) {description = this.defaults.description;}

        toastr["warning"](description, title, {
            "positionClass": "toast-top-center",
            "timeOut": "5000",
        });
    }


    clear() {
        toastr.clear();
    }
}

export default Notifier;