import React, { Component } from 'react';

class Blank extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="blank-container">
            </div>
        );
    }
}

export default Blank;
